<template>
  <div class="main_profile_wrapper w-100 px-5">
    <div class="container-fluid">
      <div class="row d-block">
        <h2 class="font-weight-bold mt-3 mb-2">Build a new team</h2>
        <p class="mb-4">
          Add members to start seeing what each team member brings to the team,
          and what they need from a team, and enjoy many of the team-specific
          features mentioned below.
        </p>
      </div>
      <div class="row mb-2">
        <div class="col-md-6 p-0 mb-4 px-2">
          <div
            class="border rounded-xl p-4 shadow-sm"
            style="min-height: 208px; background: #ffffff7a;"
          >
            <img src="../../../assets/new-theme/plus.png" alt="" class="" />
            <div class="pt-3">
              <h5 class="m-0">Add projects and tasks to that team</h5>
              <p class="text-muted">
                Enter your project's description and see how your team can work
                together using everyone's Learning Processes.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-6 p-0 mb-4 px-2">
          <div
            class="border rounded-xl p-4 shadow-sm"
            style="min-height: 208px; background: #ffffff7a;"
          >
            <img src="../../../assets/new-theme/transfer.png" alt="" class="" />
            <div class="pt-3">
              <h5 class="m-0">Team interaction</h5>
              <p class="text-muted">
                Share with your teammates what you will bring to the team and
                what you need from the team for each Learning Pattern
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-6 p-0 mb-4 px-2">
          <div
            class="border rounded-xl p-4 shadow-sm"
            style="min-height: 208px; background: #ffffff7a;"
          >
            <img src="../../../assets/new-theme/elements.png" alt="" class="" />
            <div class="pt-3">
              <h5 class="m-0">Similarities and differences</h5>
              <p class="text-muted">
                This will help all of you see the similarities and differences
                in your team uses their Learning Patterns.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-6 p-0 mb-4 px-2">
          <div
            class="border rounded-xl p-4 shadow-sm"
            style="min-height: 208px; background: #ffffff7a;"
          >
            <img src="../../../assets/new-theme/tv.png" alt="" class="" />
            <div class="pt-3">
              <h5 class="m-0">Team's LCI scores</h5>
              <p class="text-muted">
                Once they accept the invitation and complete the LCI, you will
                see the team's LCI scores both as a table and as a graph.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row pb-5">
        <div class="col-md-12 px-2 d-flex">
          <button
            class="btn btn-primary rounded-btn custom-btn mr-2 d-flex align-items-center justify-content-center"
            style="background-color: #7F56D9"
            @click="ToggleTaskModal('add')"
          >
            <img
              src="../../../assets/new-theme/white_plus.png"
              alt=""
              class=""
            />
            <span class="ml-2">
              Build a new team
            </span>
          </button>
          <button
            class="btn btn-white rounded-btn custom-btn d-flex align-items-center justify-content-center"
            @click="ToggleJoinModal('add')"
          >
            <img src="../../../assets/new-theme/link.png" alt="" class="" />
            <span class="ml-2">
              Join a team
            </span>
          </button>
        </div>
      </div>
    </div>
    <div class="main_table mx-2 mb-2">
      <TableHeaderSection
        @toggleModal="ToggleTaskModal('add')"
        @toggleJoinModal="ToggleJoinModal('add')"
        title="Your teams"
        :badge="teamsCount + ' teams'"
        text="Manage and edit your teams or build a new ones."
        :total="teamsCount"
      ></TableHeaderSection>
      <!-- buttonText="Build a new team"
        joinbuttonText="Join a team" -->
      <div>
        <CustomTable
          :count="teamsCount"
          :DataItems="allTeamsList"
          @Refresh="refresh"
          @reset="filter = {}"
          :filter="filter"
          :title="'teams'"
          hidden_export_import
          admin_permission="admin_teams"
          edit_permission="add_teams"
          @toggleModal="ToggleTaskModal('add')"
          addNewText="Add new team"
          viewName="Teams"
        >
          <template #columns>
            <el-table-column
              prop="name"
              :label="`${localization('Team Name')}`"
            ></el-table-column>
            <el-table-column
              prop="status"
              :label="`${localization('Team Status')}`"
            >
              <template #default="{ row }">
                <span
                  class="badge custom-badge-success px-2 py-1 rounded-pill letter-spacing-1"
                  v-if="row.status == 'Active'"
                  >{{ row.status }}</span
                >
                <span
                  class="badge custom-badge-info px-2 py-1 rounded-pill letter-spacing-1"
                  v-if="row.status == 'Created'"
                  >{{ row.status }}</span
                >
                <span
                  class="badge custom-badge-warning px-2 py-1 rounded-pill letter-spacing-1"
                  v-if="row.status == 'In progress'"
                  >{{ row.status }}</span
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="members"
              :label="`${localization('Number of members')}`"
            >
              <template #default="{ row }"
                >{{ row.members.length }} members</template
              >
            </el-table-column>

            <el-table-column
              prop="role"
              :label="`${localization('Your role')}`"
            >
              <template #default="{ row }">
                <span
                  class="badge custom-badge-primary px-2 py-1 rounded-pill letter-spacing-1"
                  v-if="row.role == 'Admin'"
                  >{{ row.role }}</span
                >
                <span
                  class="badge custom-badge-info px-2 py-1 rounded-pill letter-spacing-1"
                  v-else
                  >{{ row.role }}</span
                >
              </template>
            </el-table-column>
            <el-table-column prop="" label="" width="100">
              <template slot-scope="scope">
                <div class="td-actions">
                  <router-link
                    :to="`/new-theme/teams/${scope.row.id}`"
                    v-tooltip.top-center="`${localization('Show Team')}`"
                    class="btn btn-info btn-link btn-xs"
                  >
                    <i class="fa fa-eye text-dark"></i
                  ></router-link>
                </div>
              </template>
            </el-table-column>
          </template>
        </CustomTable>
      </div>
    </div>

    <!-- Start Modals  -->
    <el-dialog
      title=""
      :visible.sync="show_join_modal"
      width="600px"
      top="1%"
      custom-class="task-modal global_modal rounded-xl"
      @close="resetForm"
      :close-on-click-modal="false"
      class="dialog-center"
    >
      <div class="custom_modal_header">
        <div class="images">
          <img src="@/assets/new-theme/join.png" alt="file" />
          <img
            @click="resetForm"
            class="close_img"
            src="@/assets/new-theme/x-close-modal.png"
            alt="file"
          />
        </div>
        <h3 class="title font-weight-bold mt-3">
          Join a team
        </h3>
        <p class="description">
          Please enter the code that shared with you by email, if you can’t find
          the code check the spam file or try contact your team admin.
        </p>
      </div>

      <!-- Task Form -->
      <el-form
        :model="form"
        :rules="rules"
        ref="taskForm"
        class="task-form mb-5 pb-3"
      >
        <el-form-item prop="name" class="mt-4">
          <AppInputLabel
            text="Team Invitation code"
            :star="true"
          ></AppInputLabel>
          <el-input
            class="new_theme_custom_input"
            v-model="form.code"
            placeholder="Enter the code here"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>

      <!-- Footer Buttons -->
      <div class="lci_modal_buttons mt-5">
        <button @click="resetForm" class="lci_btn_modal_cancel ">
          Cancel
        </button>
        <button @click="submitForm" class="lci_btn save lci_btn_modal_cancel">
          Join
        </button>
      </div>
    </el-dialog>
    <!-- End Modals  -->
  </div>
</template>

<script>
import { Card } from "src/components/index";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
export default {
  name: "CareerView",

  data() {
    return {
      filter: {},
      show_join_modal: false,
      form: {
        code: null
      },
      rules: {
        code: [
          {
            required: true,
            message: "Please enter the Invitation code",
            trigger: "blur"
          }
        ]
      }
    };
  },
  computed: {
    ...mapGetters({
      loadingValue: ["user/usersLoad"],
      contactList: ["contacts/contactList"],
      allTeamsList: ["user/get_teams"],
      teamsCount: ["user/teamsCount"],
      userStatements: ["attributes/get_user_statements"],
      team_cards_content: ["user/get_team_cards_content"],
      team_pages_content: ["user/get_team_pages_content"],
      institutionUsersList: ["groups/getInstUsers"]
    })
  },
  methods: {
    refresh(query) {
      this.$store.dispatch("user/get_all_teams", { query: query });
      this.$store.dispatch("user/get_all_teams_content");
      this.$store.dispatch("attributes/GetTeamStatements", { query: query });
      this.$store
        .dispatch("attributes/getUserStatements", { query: query })
        .then(_ => {
          console.log(
            " loadingValue >>> ",
            this.loadingValue,
            "\n",
            " contactList >>> ",
            this.contactList,
            "\n",
            " allTeamsList >>> ",
            this.allTeamsList,
            "\n",
            " userStatements >>> ",
            this.userStatements,
            "\n",
            " team_cards_content >>> ",
            this.team_cards_content,
            "\n",
            " team_pages_content >>> ",
            this.team_pages_content,
            "\n",
            " institutionUsersList >>> ",
            this.institutionUsersList,
            "\n"
          );
        });
    },
    ToggleJoinModal(query) {
      this.show_join_modal = true;
    },
    resetForm() {
      this.show_join_modal = false;
      this.form = {
        code: null
      };
    },
    ToggleTaskModal() {
      this.$router.push({ name: "step-1" });
    },
    submitForm() {
      this.$refs.taskForm.validate(valid => {
        if (valid) {
          this.$store
            .dispatch("teams/join_team", {
              query: this.form
            })
            .then(_ => {
              this.show_join_modal = false;
              Swal.fire({
                title: "",
                icon: "success",
                text: `${this.localization("Done")}`,
                confirmButtonText: `${this.localization("Ok")}`,
                type: "success",
                confirmButtonClass: "btn btn-success ",
                buttonsStyling: false
              });

              this.refresh({});
            })
            .catch(_ => {
              this.show_join_modal = false;
            });
        }
      });
    }
  },
  mounted() {
    this.refresh();
  }
};
</script>

<style lang="scss" scoped>
.main_profile_wrapper {
  background: url("../../../assets/new-theme/lci/background.png") no-repeat
    right top;
}
.description {
  word-break: break-word;
  overflow-wrap: break-word;
}
</style>
