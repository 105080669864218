<template>
  <div class="global_theme_ui_page main_task_details_page_wrapper ">
    <!-- Start Patterns -->
    <UserPattern :userScore="userScore"></UserPattern>
    <!-- End Patterns -->
    <!-- Task Decoding -->
    <div class="decode_task_section">
      <div
        class="d-flex justify-content-between align-items-center mb-3 hide_element_while_printing"
      >
        <h2 class="main_title m-0">Task Decoding</h2>
        <div
          role="button"
          class="d-flex align-items-center"
          style="gap: 10px"
          @click="ToggleTaskModalForEdit()"
        >
          <img src="@/assets/new-theme/edit-05.png" alt="edit" />
          <span class="primary_theme_color">
            Edit task description
          </span>
        </div>
      </div>
      <div class="wrap_decode">
        <div
          class="wrap_title d-flex align-items-center justify-content-between"
        >
          <div class="part_one">
            <img src="@/assets/new-theme/logo.png" alt="logo" />
            <span class="span_title">Task description</span>
            <span class="span_description"> - {{ mainTaskData.name }}</span>
          </div>
          <div
            class="d-flex align-items-center hide_element_while_printing"
            style="gap: 10px"
          >
            <el-switch
              v-model="switchValue"
              active-color="#6941c6"
              inactive-color="#DCDFE6"
            >
            </el-switch>
            <span>
              Border style
            </span>
          </div>
        </div>
        <div
          class="for-task-description global_decode_content"
          :class="{ border_style: switchValue }"
          v-html="mainTaskData.description"
        ></div>
      </div>
    </div>

    <div class="mt-5 hide_element_while_printing">
      <SharePrintCard
        title="Types of usage patterns"
        text="There are 3 different types of usage patterns as described below."
        @print="printPlp"
        @share="toggleModalShare()"
        class="hide_element_while_printing"
      ></SharePrintCard>
    </div>

    <!-- Use Your Learning Patterns -->
    <div class="learner_patterns_wrapper hide_element_while_printing">
      <div class="main_cards mt-3">
        <div class="card_item">
          <div class="mb-3">
            <img class="img" src="@/assets/new-theme/career-1.png" alt="" />
          </div>
          <div class="mb-4">
            <span class="title mb-2 d-block">
              Essential Pattern
            </span>
            <p class="mb-0 description">
              An Essential Pattern is a Pattern that is vital for you to have in
              order to do the (job/career) successfully.
            </p>
          </div>
        </div>
        <div class="card_item">
          <div class="mb-3">
            <img class="img" src="@/assets/new-theme/career-2.png" alt="" />
          </div>
          <div class="mb-4">
            <span class="title mb-2 d-block">
              Supportive Pattern
            </span>
            <p class="mb-0 description">
              A Pattern that either you use at the "As Needed" level when a "Use
              First" level is needed to do the job successfully OR you Avoid a
              Pattern which is not needed to do the job or succeed in the
              career.
            </p>
          </div>
        </div>
        <div class="card_item">
          <div class="mb-3">
            <img class="img" src="@/assets/new-theme/career-3.png" alt="" />
          </div>
          <div class="mb-4">
            <span class="title mb-2 d-block">
              Detractor Pattern
            </span>
            <p class="mb-0 description">
              A Detractor Pattern detracts you when you Use it First, but it is
              not needed in the job /career OR it is needed, but you Avoid that
              Learning Pattern.
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- Use Your Learning Patterns -->

    <!-- Start Collapse Items -->
    <div class="global_theme_ui_collapse main_profile_personalize_and_collapse">
      <el-collapse
        class="mt-3 customization_for_collapse"
        v-model="panel"
        multiple
      >
        <div
          class="main_child "
          v-for="(attribute, i) in userAttributes"
          :key="i"
          :style="`border : 2px solid ${getProgressColor(i)}`"
        >
          <el-collapse-item
            :name="`${i}`"
            class=""
            :class="{ break_section: i == 'Precision' }"
          >
            <template #title>
              <div class="custom_header_of_collapse">
                <img
                  src="@/assets/new-theme/attributes-images/sequence.png"
                  alt="sequence"
                  v-if="i == 'Sequence'"
                />
                <img
                  src="@/assets/new-theme/attributes-images/precision.png"
                  alt="precision"
                  v-if="i == 'Precision'"
                />
                <img
                  src="@/assets/new-theme/attributes-images/technical_reasoning.png"
                  alt="technical_reasoning"
                  v-if="i == 'Technical Reasoning'"
                />
                <img
                  src="@/assets/new-theme/attributes-images/confluence.png"
                  alt="confluence"
                  v-if="i == 'Confluence'"
                />
                <span class="title_word">{{ i }} - {{ patternKey(i) }}</span>
              </div>
            </template>

            <!-- Start Checkboxes -->
            <div class="p-3">
              <el-collapse class="collapse_checkboxes">
                <div
                  class="checkboxes_wrapper"
                  v-for="(item, index) in attribute"
                  :key="index"
                >
                  <div>
                    {{ item.strategy }}
                  </div>
                  <audio controls id="audio" class="d-none">
                    <source id="audio-source" type="audio/mp3" />
                  </audio>
                  <span
                    class="span_img_wrapper hide_element_while_printing"
                    role="button"
                    @click="text_speech_audio(item.strategy)"
                  >
                    <img
                      src="@/assets/new-theme/attributes-images/volume.png"
                      alt="volume"
                    />
                  </span>
                </div>
              </el-collapse>
            </div>
            <!-- End Checkboxes  -->
          </el-collapse-item>
        </div>
      </el-collapse>
    </div>
    <!-- Start Collapse Items -->
    <!-- Start What else can you do? -->
    <div
      class="your_personal_wrapper margin_bottom hide_element_while_printing"
    >
      <h2 class="main_title ">What else can you do?</h2>
      <p class="mb-0 text">
        Print or share it with your teacher, instructor, classmates, colleagues,
        or even for a job inerview.
      </p>
      <div class="print_share_buttons ">
        <button class="btn_2 print" @click="printPlp()">
          <img
            class="img"
            src="@/assets/new-theme/profile/printer.png"
            alt=""
          />
          <span>Print this task</span>
        </button>
        <button class="btn_2 share" @click="toggleModalShare()">
          <img
            class="img"
            src="@/assets/new-theme/profile/share-07.png"
            alt=""
          />
          <span>Share this task</span>
        </button>
      </div>
    </div>
    <!-- Start What else can you do? -->
    <div
      class="your_personal_wrapper margin_bottom hide_element_while_printing"
    >
      <h2 class="main_title ">
        Do you have a task related to this job and need a strategies to complete
        it?
      </h2>
      <p class="mb-0 text">
        Create a new work-related tasks directly from here and discover what
        patterns are needed to successfully complete it.
      </p>
    </div>

    <!-- delete section -->
    <div class="hide_element_while_printing delete_section">
      <div>
        <h2 class="main_title ">You don't need this task anymore!</h2>
        <p class="mb-0 text">
          Note: If you delete the task, you cannot restore it again.
        </p>
      </div>
      <button class="delete_btn" @click="deleteTask()">
        <img
          role="button"
          src="@/assets/new-theme/profile/trash-01.png"
          alt="delete"
        />
        <span>Delete this task</span>
      </button>
    </div>
    <!-- Task Modal -->
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="600px"
      top="1%"
      custom-class="task-modal global_modal"
      @close="resetForm"
      :close-on-click-modal="false"
    >
      <div class="custom_modal_header">
        <div class="images">
          <img src="@/assets/new-theme/file.png" alt="file" />
          <img
            @click="resetForm"
            class="close_img"
            src="@/assets/new-theme/x-close-modal.png"
            alt="file"
          />
        </div>
        <h3 class="title">Edit your job-related task</h3>
        <p class="description">
          Edit your own job-related task and discover what patterns are needed
          to successfully complete it.
        </p>
      </div>

      <!-- Task Form -->
      <el-form :model="task" :rules="rules" ref="taskForm" class="task-form">
        <div class="flex justify-content-between" style="gap: 10px">
          <div class="w-50">
            <!-- Task Name -->
            <el-form-item prop="name">
              <AppInputLabel text="Name your task" :star="true"></AppInputLabel>
              <el-input
                class="new_theme_custom_input"
                v-model="task.name"
                placeholder="Give you task a name"
                clearable
              ></el-input>
            </el-form-item>
          </div>
          <!-- <div class="flex-grow-1">
            <el-form-item prop="topic_id">
              <AppInputLabel text="Task category" :star="true"></AppInputLabel>
              <el-select
                v-model="task.topic_id"
                clearable
                :placeholder="`${localization('Topic')}`"
                style="width: 100%"
              >
                <el-option
                  v-for="(item, index) in standardTopicsData"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </div> -->
        </div>

        <!-- Task Description -->
        <el-form-item prop="description">
          <AppInputLabel text="Task description" :star="true"></AppInputLabel>
          <el-input
            class="new_theme_custom_input input_with_custom_text_area"
            type="textarea"
            autocomplete="off"
            v-model="task.description"
            placeholder="Add task description"
            clearable
          />
        </el-form-item>
      </el-form>

      <!-- Footer Buttons -->
      <div class="lci_modal_buttons mt-4">
        <button @click="resetForm" class="lci_btn_modal_cancel ">
          Cancel
        </button>
        <button @click="submitForm" class="lci_btn save  lci_btn_modal_cancel">
          Confirm
        </button>
      </div>
    </el-dialog>
    <!-- End Add Task Modal -->

    <!--  Start Share -->
    <ShareModal
      :showHideModalShare="showHideModalShare"
      @close="toggleModalShare"
      @submit="toggleModalShare"
      title="Share your Task"
      description="Share your personalized work profile and learning patterns score with others to let them know how you learn, work, and interact with others."
    ></ShareModal>
    <!--  End Share -->
  </div>
</template>

<script>
import Swal from "sweetalert2";
export default {
  name: "CareerDetails",
  data() {
    return {
      showHideModalShare: false,
      panel: [`Precision`, `Sequence`, "Technical Reasoning", "Confluence"],
      selected: [],
      taskStrategies: [],
      mainTask: {},
      switchValue: false,
      task: {
        name: "",
        topic_id: "",
        description: ""
      },

      dialogVisible: false,
      rules: {
        name: [
          {
            required: true,
            message: "Please enter the task name",
            trigger: "blur"
          }
        ],
        topic_id: [
          {
            required: true,
            message: "Please enter the task topic",
            trigger: "blur"
          }
        ],
        description: [
          {
            required: true,
            message: "Please enter a task description",
            trigger: "blur"
          }
        ]
      }
    };
  },
  computed: {
    mainUserId() {
      return this.auth().id;
    },
    userScore() {
      return this.$store.getters["user/score"];
    },
    userAttributes() {
      return this.taskStrategies;
      // return this.$store.getters["user/onetask"];
    },
    mainTaskData() {
      return this.mainTask;
      // return this.$store.getters["user/onetask"];
    },
    isAuthEqualUser() {
      return this.auth().id == this.mainUserId;
    },
    userData() {
      return this.$store.state.user.user;
    },
    taskId() {
      return this.$route.params.task_id;
    },
    standardTopicsData() {
      return this.$store.getters["topics/getStandardTopics"];
    }
  },
  methods: {
    deleteTask() {
      Swal.fire({
        title: "",
        text: `${this.localization("Are You Sure")}`,
        type: "warning",
        icon: "error",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success ",
        cancelButtonClass: "btn btn-danger ",
        confirmButtonText: `${this.localization("Delete")}`,
        cancelButtonText: `${this.localization("Cancel")}`,
        buttonsStyling: false
      }).then(date => {
        if (date.isConfirmed) {
          let payload = {
            tasks: [this.$route.params.task_id]
          };
          this.$store
            .dispatch("user/handleDeleteCareerMatchTask", payload)
            .then(_ => {
              this.$router.push("/new-theme/career");
              Swal.fire({
                title: "",
                icon: "success",
                type: "success",
                text: `${this.localization("Deleted")}`,
                confirmButtonText: `${this.localization("Ok")}`,
                confirmButtonClass: "btn btn-success ",
                buttonsStyling: false
              });
            });
        }
      });
    },
    getMainUserData() {
      this.$store.dispatch("user/user", this.mainUserId).then(() => {
        // Set the Selected User attribute
        // this.selected = this.userData.selected_attributes;
        this.$store.dispatch("topics/handlerStandardTopics");
      });
    },
    printPlp() {
      window.print();
    },
    // Toggle Modal User Type
    toggleModalShare() {
      this.showHideModalShare = !this.showHideModalShare;
    },
    getProgressColor(key) {
      switch (key) {
        case "Precision":
          return "#3c3";
        case "Technical Reasoning":
          return "#f93";
        case "Confluence":
          return "#c36";
        case "Sequence":
          return "#39c";
      }
    },
    // Return pattern key in collapse
    patternKey(name) {
      if (name == "Sequence") {
        // Start Pattern range
        if (this.userScore?.sequence >= 25 && this.userScore.sequence <= 35) {
          return "Use First";
        } else if (
          this.userScore?.sequence >= 7 &&
          this.userScore.sequence <= 17
        ) {
          return "Avoid";
        } else if (
          this.userScore?.sequence >= 18 &&
          this.userScore.sequence <= 24
        ) {
          return "As Needed";
        }
        // End Pattern range
      }
      if (name == "Precision") {
        // Start Pattern range
        if (this.userScore?.precision >= 25 && this.userScore.precision <= 35) {
          return "Use First";
        } else if (
          this.userScore?.precision >= 7 &&
          this.userScore.precision <= 17
        ) {
          return "Avoid";
        } else if (
          this.userScore?.precision >= 18 &&
          this.userScore.precision <= 24
        ) {
          return "As Needed";
        }
        // End Pattern range
      }
      if (name == "Technical Reasoning") {
        // Start Pattern range
        if (
          this.userScore?.technical_reasoning >= 25 &&
          this.userScore.technical_reasoning <= 35
        ) {
          return "Use First";
        } else if (
          this.userScore?.technical_reasoning >= 7 &&
          this.userScore.technical_reasoning <= 17
        ) {
          return "Avoid";
        } else if (
          this.userScore?.technical_reasoning >= 18 &&
          this.userScore.technical_reasoning <= 24
        ) {
          return "As Needed";
        }
        // End Pattern range
      }
      if (name == "Confluence") {
        // Start Pattern range
        if (
          this.userScore?.confluence >= 25 &&
          this.userScore.confluence <= 35
        ) {
          return "Use First";
        } else if (
          this.userScore?.confluence >= 7 &&
          this.userScore.confluence <= 17
        ) {
          return "Avoid";
        } else if (
          this.userScore?.confluence >= 18 &&
          this.userScore.confluence <= 24
        ) {
          return "As Needed";
        }
        // End Pattern range
      }
    },
    // Get task details
    refresh() {
      this.$store
        .dispatch("user/showCareerMatchTask", { id: this.taskId })
        .then(res => {
          this.taskStrategies = res?.task?.strategy_id;

          // console.log("from task details: ", res.task.strategy_id);
          this.mainTask = res?.task;

          this.selected =
            res?.task?.selected_strategy_id !== null
              ? res?.task?.selected_strategy_id
              : [];

          // this.$store.dispatch("user/user", this.user).then(res => {
          //   console.log("i am user here");
          // });
          // this.selected_panel = [];
          // this.strategies = this.task.selected_strategy_id
          //   ? this.task.selected_strategy_id
          //   : [];
          //  handling tasks tooltip
          let html_task_handler = document.querySelector(
            ".for-task-description"
          );

          const all_child_nodes = Array.from(
            html_task_handler.getElementsByTagName("div")
          );

          all_child_nodes.forEach(element => {
            let get_tooltip_data = element.getAttribute("data-tooltip");
            let create_span = document.createElement("span");
            create_span.textContent = get_tooltip_data;
            create_span.setAttribute("class", "span_for_tooltips");
            element.appendChild(create_span);
          });
        });
    },
    updateSelectedAttributes() {
      this.$store
        .dispatch("user/update_task", {
          id: this.taskId,
          query: { selected_strategy_id: this.selected }
        })
        .then(() => {
          this.getMainUserData();
          this.refresh();
        });
    },

    text_speech_audio(any_text) {
      this.$store
        .dispatch("user/get_text_to_speech_audio", { text: any_text })
        .then(res => {
          let audioBlob = new Blob([res], { type: "audio/mpeg" });
          let audioURL = URL.createObjectURL(audioBlob);
          let audio = document.getElementById("audio");
          var source = document.getElementById("audio-source");
          source.src = audioURL;
          audio.load();
          audio.play();
        });
    },
    // Edit Task
    ToggleTaskModalForEdit() {
      console.log("🚀 ~ ToggleTaskModalForEdit ~ mainTask:", this.mainTask);
      this.task.name = this.mainTask.name;
      this.task.description = this.task.description = this.mainTask.description.replace(/<\/?[^>]+(>|$)/g, "");
      this.task.topic_id = this.mainTask.topic_id;
      this.dialogVisible = !this.dialogVisible;
    },
    resetForm() {
      this.$refs.taskForm.resetFields();
      this.dialogVisible = false;
      // this.$v.$reset();
    },
    submitForm() {
      this.$refs.taskForm.validate(valid => {
        if (valid) {
          this.$store
            .dispatch("user/handleEditCareerMatchTask", {
              query: this.task,
              id: this.taskId
            })
            .then(_ => {
              this.dialogVisible = false;

              Swal.fire({
                title: "",
                icon: "success",
                text: `${this.localization("Done")}`,
                confirmButtonText: `${this.localization("Ok")}`,
                type: "success",
                confirmButtonClass: "btn btn-success ",
                buttonsStyling: false
              });

              this.refresh({});
            })
            .catch(_ => {
              this.dialogVisible = false;
            });
        }
      });
    }
  },
  mounted() {
    this.getMainUserData();
    this.refresh();

  }
};
</script>

<style lang="scss" scoped>
@media print {
  * {
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
    color-adjust: exact !important; /*Firefox*/
  }
}
.main_task_details_page_wrapper {
  // Use Your Learning Patterns
  .learner_patterns_wrapper {
    margin-top: 40px;
    .titles_wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .print_share_buttons {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
    .btn_2 {
      border: 1px solid transparent;
      border-radius: 8px;
      color: #6941c6;
      font-size: 14px;
      height: 41px;
      padding: 10px 16px;
      display: flex;
      align-items: center;
      font-weight: 500;
      gap: 10px;
      background: #fff;
    }
    .text {
      color: #667085;
      font-weight: 400;
      font-size: 16px;
    }
    .main_cards {
      display: flex;
      justify-content: space-between;
      gap: 4%;
      .card_item {
        border-radius: 8px;
        border: 1px solid #eaecf0;
        padding: 24px;
        width: calc(96% / 3);

        .title {
          color: #101828;
          font-weight: 600;
          font-size: 18px;
        }
        .description {
          color: #667085;
          font-weight: 400;
          font-size: 16px;
        }
        .lci_btn {
          background-color: #f2f4f7;
          color: #6941c6;
          border-radius: 8px;
          padding: 12px 20px;
          border: 1px solid #eaecf0;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
          width: 100%;
          font-weight: 500;
          font-size: 16px;
        }
      }
    }
  }

  .main_title {
    color: #101828;
    font-weight: 600;
    font-size: 20px;
    &.white_text {
      color: #fff;
    }
  }
  .text {
    color: #667085;
    font-weight: 400;
    font-size: 16px;
  }
  .your_personal_wrapper {
    border-radius: 8px;
    border: 1px solid #eaecf0;
    padding: 24px;
    &.margin_bottom {
      margin-bottom: 24px;
    }
    .print_share_buttons {
      display: flex;
      align-items: center;
      gap: 20px;
      margin-top: 20px;
      .btn_2 {
        border: 1px solid transparent;
        border-radius: 8px;
        color: #6941c6;
        font-size: 14px;
        height: 41px;
        padding: 10px 16px;
        display: flex;
        align-items: center;
        font-weight: 500;
        gap: 10px;
        background: #fff;
        flex-grow: 1;
        justify-content: center;
        &.share {
          background-color: #f9f5ff;
        }
        &.print {
          border-color: #6941c6;
        }
      }
    }
  }
  .delete_section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .delete_btn {
      border: none;
      background: none;
      color: #b42318;
      display: flex;
      align-items: center;
      gap: 5px;
      font-size: 14px;
      font-weight: 500;
    }
  }
}
</style>

<style lang="scss">
.user_modal {
  padding: 0;
  .el-dialog__header {
    display: none !important;
  }
}

.main_profile_personalize_and_collapse {
  .el-collapse {
    border: none !important;
  }
  .el-collapse-item__content {
    box-shadow: none !important;
    border: none !important;

    // @media print {
    //   padding-bottom: 0;
    // }
  }
  .el-collapse-item__wrap {
    border-bottom: none !important;
  }
  .el-collapse-item__content {
    padding-bottom: 0;
    font-size: 16px;
    line-height: inherit;
    color: #344054 !important;
  }
  .el-checkbox__inner {
    border: 1px solid #d0d5dd;
    border-radius: 6px;
    height: 20px;
    width: 20px;
  }
  .el-checkbox {
    align-items: flex-start;
    display: flex;
  }
  .el-checkbox__label,
  .el-checkbox is-checked {
    color: #344054 !important;
    font-weight: 400;
    font-size: 16px;

    // max-width: 98%;
    word-break: break-word !important;
    white-space: pre-wrap;
    // word-wrap: break-word;
    white-space: normal;
  }
  .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #6941c6;
    border-color: #6941c6;
  }
  .el-checkbox__inner::after {
    left: 7px;
    top: 4px;
    border-width: 2px;
  }
}
</style>
