<template>
  <div class="main_profile_wrapper" v-if="hasPermission('access_groups')">
    <div class="main_table">
      <TableHeaderSection
        @toggleModal="ToggleTaskModal('add')"
        @toggleInviteModal="ToggleInviteModal('add')"
        :invitebuttonText="'Invite Members'"
        title="My groups"
        text="Organize your contacts into groups and see the group dynamic."
        buttonText="Create new group"
        :total="groupsCount"
      ></TableHeaderSection>
      <div class="header_container just_custom_table_tasks">
        <CustomTable
          :count="groupsCount"
          :DataItems="userGroups"
          @Refresh="refresh"
          @reset="filter = {}"
          :filter="filter"
          :title="'groups'"
          hidden_export_import
          @reset-filters="filter = {}"
          admin_permission="admin_groups"
          edit_permission="add_groups"
        >
        <template #columns>
          <el-table-column prop="name" :label="`${localization('Group Name')}`"></el-table-column>
          <el-table-column prop="groups_members_count" :label="`${localization('Number of members')}`">
            <template #default="{ row }"
                >{{ row.groups_members_count }} members</template
              >
          </el-table-column>
          <el-table-column
              prop="my_role"
              :label="`${localization('Your role')}`"
            >
              <template #default="{ row }">
                <span
                  class="badge custom-badge-primary px-2 py-1 rounded-pill letter-spacing-1"
                  v-if="row.my_role == 'Admin'"
                  >{{ row.my_role }}</span
                >
                <span
                  class="badge custom-badge-info px-2 py-1 rounded-pill letter-spacing-1"
                  v-else
                  >{{ row.my_role }}</span
                >
              </template>
          </el-table-column>
          <el-table-column prop="" label="" width="100">
            <template slot-scope="scope">
              <div class="td-actions">
                <router-link
                  :to="`/new-theme/groups/${scope.row.id}`"
                  v-tooltip.top-center="`${localization('Show Group')}`"
                  class="btn btn-info btn-link btn-xs"
                >
                  <i class="fa fa-eye text-dark"></i
                ></router-link>
              </div>
            </template>
          </el-table-column>
        </template>
        </CustomTable>
        </div>
    </div>

    <el-dialog
      @close="resetForm"
      title="Add Group"
      :visible.sync="dialogVisible"
      width="600px"
      top="1%"
      custom-class="task-modal global_modal"
      :close-on-click-modal="true"
    >
      <div class="custom_modal_header">
        <div class="images">
          <img src="@/assets/new-theme/icons/users-plus.png" alt="file" />
          <img
            @click="resetForm"
            class="close_img"
            src="@/assets/new-theme/x-close-modal.png"
            alt="file"
          />
        </div>
        <h3 class="title font-weight-bold mt-3">
          Create new group
        </h3>
        <p class="description">
          Enter the team name and add members to it.
        </p>
      </div>
      <el-form 
        :rules="rules" 
        ref="addGroup"
        :model="form"
      >
        <el-form-item
          :label="`${localization('Name your group *')}`"
          prop="name"
        >
          <el-input
            :placeholder="`${localization('Give you group a name')}`"
            v-model="form.name"
          ></el-input>
        </el-form-item>

      </el-form>
      <div class="lci_modal_buttons mt-5">
        <button @click="resetForm" class="lci_btn_modal_cancel ">
          Cancel
        </button>
        <button @click="submitForm" class="lci_btn save lci_btn_modal_cancel">
          Create
        </button>
      </div>
    </el-dialog>
    <el-dialog
      title="Invite members"
      :visible.sync="show_invite_modal"
      width="600px"
      top="1%"
      custom-class="task-modal global_modal"
      :close-on-click-modal="true"
    >
      <div class="custom_modal_header">
        <div class="images">
          <img src="@/assets/new-theme/icons/users-plus.png" alt="file" />
          <img
            @click="resetForm"
            class="close_img"
            src="@/assets/new-theme/x-close-modal.png"
            alt="file"
          />
        </div>
        <h3 class="title font-weight-bold mt-3">
          Invite members to your group *
        </h3>
        <p class="description">
          Invite members to your group by any of the following methods.
        </p>
      </div>
      <el-form 
        :rules="rules" 
        ref="contactRef"
      >
        <el-form-item :label="`${localization('Members')}`">
          <el-select
            ref="users"
            :placeholder="`${localization('Select Members')}`"
            style="width: 100%"
            v-model="selected_additionam_group_members"
            filterable
            multiple
            remote
            reserve-keyword
            :remote-method="search"
            :loading="loadingValue"
          >
            <el-option
              v-for="user in contactList"
              :key="user.id"
              :label="user.first_name + ' ' + user.last_name"
              :value="user.id"
            >
              <span style="color: #8492a6; font-size: 13px">
                <i class="el-icon-user-solid"></i>
                {{ user.first_name + " " + user.last_name }}
              </span>
            </el-option>
          </el-select>
          <div>Or invite members by email</div>
          <el-form-item
            label="Email"
            style="width: 100%"
            prop="questions_options"
          >
            <el-select
              v-model="emails"
              no-data-text="Type Email"
              multiple
              filterable
              allow-create
              default-first-option
              class=""
              style="width: 100%"
              placeholder="Type Email"
            >
            </el-select>
          </el-form-item>
        </el-form-item>
        <el-form-item label="Institution Users" prop="">
          <el-select
            v-model="selected_institutions_users"
            :placeholder="`${localization('Please select user')}`"
            style="width: 100%"
            multiple
            filterable
          >
            <el-option
              v-for="item in institutionUsersList"
              :key="item.id"
              :label="item.first_name + ' ' + item.last_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="lci_modal_buttons mt-5">
        <button @click="resetForm" class="lci_btn_modal_cancel ">
          Cancel
        </button>
        <button @click="inviteForm" class="lci_btn save lci_btn_modal_cancel">
          Invite
        </button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
export default {
  name: "CareerView",
  data() {
    return {
      showAddTask: false,
      dialogVisible: false,
      show_invite_modal: false,
      task: {
        name: "",
        topic_id: "",
        description: ""
      },
      rules: {
        name: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
      },
      form:{
        name: ''
      },
      fileList: [],
      selected_additionam_group_members: [],
      emails: [],
      filter: {},
      selected_institutions_users: [],
      search_group: [],
      selected: [],
    };
  },
  computed: {
    mainUserId() {
      return this.$route.params.id ? this.$route.params.id : this.auth().id;
    },
    ...mapGetters({
      loadingValue: ["groups/usersLoad"],
      userGroups: ["groups/userGroups"],
      groupsCount: ["groups/groupsCount"],
      institutionUsersList: ["groups/getInstUsers"],
      contactList: ["contacts/contactList"],
      load: ["groups/usersLoad"],
    })
  },
  methods: {
    refresh(query) {
      this.$store.dispatch("groups/GetUserGroups", { query: query })
    },
    ToggleTaskModal() {
      this.dialogVisible = true;
    },
    ToggleInviteModal(query) {
      this.show_invite_modal = true;
    },
    resetForm(){
      this.form.name = '';
      this.dialogVisible = false;
      this.show_invite_modal = false;
    },
    submitForm() {
      this.$nextTick(() => {
        if (this.$refs.addGroup) {
          this.$refs.addGroup.validate((valid) => {
            if (valid) {
              // Proceed with form submission logic
              this.$store
                .dispatch("groups/addNewGroupMembers", {
                  name: this.form.name,
                })
                .then(() => {
                  this.dialogVisible = false;
                  Swal.fire({
                    title: "",
                    icon: "success",
                    text: `${this.localization("Done")}`,
                    confirmButtonText: `${this.localization("Ok")}`,
                    type: "success",
                    confirmButtonClass: "btn btn-success",
                    buttonsStyling: false,
                  });
                  this.refresh();
                })
                .catch(() => {
                  this.dialogVisible = false;
                });
            }
          });
        } else {
          console.error("Form reference (addGroup) is undefined.");
        }
      });
    },
    inviteForm() {
      this.$nextTick(() => {
        if (this.$refs.addGroup) {
          this.$refs.addGroup.validate((valid) => {
            if (valid) {
              // Proceed with form submission logic
              this.$store
                .dispatch("groups/addNewGroupMembers", {
                  name: this.form.name,
                })
                .then(() => {
                  this.dialogVisible = false;
                  Swal.fire({
                    title: "",
                    icon: "success",
                    text: `${this.localization("Done")}`,
                    confirmButtonText: `${this.localization("Ok")}`,
                    type: "success",
                    confirmButtonClass: "btn btn-success",
                    buttonsStyling: false,
                  });
                  this.refresh();
                })
                .catch(() => {
                  this.dialogVisible = false;
                });
            }
          });
        } else {
          console.error("Form reference (addGroup) is undefined.");
        }
      });
    },
    search(query) {
      this.usersRoles(query);
    },
    usersRoles(search = "", limit = 10) {
      this.$store.dispatch("contacts/GetContactsList", {
          search_text: search
        })
        .then(() => {
          this.search_group = this.$store.getters["contacts/contactList"];
          // this.search_group = this.$store.getters["contacts/searchContacts"];
        });
    },
  },
  mounted() {
    this.refresh();
  }
};
</script>

<style lang="scss" scoped>
.pwp{
  background: linear-gradient(
  90deg,
  rgba(255, 255, 255, 1) 45%,    /* Pure white */
  rgba(244, 244, 252, 1) 60%,   /* Light purple */
  rgba(232, 248, 242, 1) 70%,   /* Light green */
  rgba(253, 232, 224, 1) 100%   /* Light peach/orange */
);
    border-radius: 8px;
    border: 1px solid #eaecf0;
    padding: 14px 14px 25px;
}
.redirect_to_pwp {
      display: flex;
      align-items: center;
      gap: 20px;
      margin-top: 20px;
      .btn_2 {
            border: 1px solid transparent;
            border-radius: 8px;
            color: #6941c6;
            font-size: 15px;
            height: 41px;
            padding: 10px 16px;
            display: flex;
            align-items: center;
            font-weight: 500;
            gap: 10px;
            background: transparent;
            justify-content: center;
            &.share{
              background-color: #F9F5FF;
            }
            &.print{
              border-color: #6941c6;
              color: #667085;
            }
          }
    }
.lci_modal_buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3%;
  .lci_btn_modal_cancel {
    background-color: #fff;
    color: #667085;
    border-radius: 8px;
    padding: 12px 20px;
    border: 1px solid #eaecf0;
    width: calc(97% / 2);
    &.save {
      background-color: #7f56d9;
      color: #fff;
    }
  }
}
.main_custom_parts_pattern {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  align-items: center;
  .part_pattern {
    border: 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    border-radius: 16px;
    height: 25px;
    padding: 10px 13px;
    width: 55px;
    font-weight: 500;
    &.s {
      color: #39c;
      border-color: #39c;
    }
    &.p {
      color: #3c3;
      border-color: #3c3;
    }
    &.t {
      color: #f93;
      border-color: #f93;
    }
    &.c {
      color: #c36;
      border-color: #c36;
    }
  }
}
.text {
  color: #667085;
  font-weight: 400;
  font-size: 16px;
}
.main_profile_wrapper {
  background: #fff;
  width: 100%;
  max-width: 100%;
  padding: 0 30px 50px;
}
.main_title {
  color: #101828;
  font-weight: 600;
  font-size: 20px;
  &.white_text {
    color: #fff;
  }
}
.padding {
  padding: 14px;
}
.main_table {
  margin-top: 30px;
  border-radius: 8px;
  border: 1px solid #eaecf0;

  .head_table {
    border-bottom: 1px solid #eaecf0;
  }
}
</style>

<style scoped>
.task-modal {
  padding: 20px;
}
.task-type {
  margin-bottom: 20px;
}
.task-form {
  margin-bottom: 20px;
}
.file-upload {
  margin-bottom: 20px;
  text-align: center;
}
.uploaded-files {
  margin: 20px 0;
}
.uploaded-files h4 {
  margin-bottom: 10px;
}
.uploaded-files ul {
  list-style: none;
  padding: 0;
}
.uploaded-files li {
  font-size: 14px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.uploaded-files li .el-icon-document {
  margin-right: 10px;
}
.file-delete {
  cursor: pointer;
  color: red;
  margin-left: 10px;
  font-size: 18px;
}
.el-upload {
  border: 2px dashed #d9d9d9;
  padding: 20px;
  border-radius: 5px;
}
.el-upload__text {
  color: #409eff;
  font-size: 16px;
}
.el-upload__tip {
  color: #909399;
}
.dialog-footer {
  text-align: right;
}
.el-button {
  margin-left: 10px;
}
.el-progress {
  margin: 20px 0;
}

/* Custom Radio Buttons */
.custom-radio-group {
  display: flex;
  justify-content: space-between;
}

.custom-radio {
  display: inline-flex;
  align-items: center;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  transition: border-color 0.3s;
}

.custom-radio.is-checked {
  border-color: #67c23a;
  background-color: rgba(103, 194, 58, 0.1);
}

::v-deep .el-form-item__label:before{
  content: unset !important;
}
</style>
