<template>
  <div class="share_print_card">
    <div class="titles_wrap">
      <h2 class="main_title ">{{ title }}</h2>
      <div class="print_share_buttons">
        <button class="btn_2" @click="printPlp()" v-if="displayPrint">
          <img
            class="img"
            src="@/assets/new-theme/profile/printer.png"
            alt=""
          />
          <span>Print</span>
        </button>
        <button class="btn_2" @click="toggleModalShare()" v-if="displayShare">
          <img
            class="img"
            src="@/assets/new-theme/profile/share-07.png"
            alt=""
          />
          <span>Share</span>
        </button>
      </div>
    </div>
    <p class="mb-0 text">
      {{ text }}
    </p>
  </div>
</template>

<script>
export default {
  name: "SharePrintCard",
  props: {
    title: {
      type: String,
      default: ""
    },
    text: {
      type: String,
      default: ""
    },
    displayPrint: {
      type: Boolean,
      default: true
    },
    displayShare: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {};
  },
  methods: {
    printPlp() {
      this.$emit("print");
    },
    toggleModalShare() {
      this.$emit("share");
    }
  }
};
</script>

<style lang="scss" scoped>
// Use Your Learning Patterns
.share_print_card {
  .titles_wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .print_share_buttons {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
  .btn_2 {
    border: 1px solid transparent;
    border-radius: 8px;
    color: #6941c6;
    font-size: 14px;
    height: 41px;
    padding: 10px 16px;
    display: flex;
    align-items: center;
    font-weight: 500;
    gap: 10px;
    background: transparent;
  }
  .text {
    color: #667085;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 1.1px;

  }

  .main_title {
    color: #101828;
    font-weight: 600;
    font-size: 20px;
  }
}
</style>
