<template>
  <div class="main_lci_wrapper ">
    <div class="main_layout d-flex">
      <div class="cards_and_logo ">
        <div class="render_action">
          <img src="@/assets/new-theme/PLC-Logo.svg" alt="" />

          <img
            v-if="hideXIcon"
            @click="hideAndShowSidebar"
            role="button"
            src="@/assets/new-theme/close.png"
            alt="x-close"
          />
        </div>
        
        <div v-if="hideShowSidebar == false">
          <h4 class="mb-0 pb-0" >Why is this important?</h4>
          <div class="cards_wrapper" v-for="(item, i) in cardItems" :key="i">
            <LciCard :item="item"></LciCard>
          </div>
        </div>
        <div
          class="mt-5 absolute_question_mark"
          v-if="!hideXIcon"
          :class="{ make_absolute: hideShowSidebar == true }"
        >
          <div
            @click="hideAndShowSidebar"
            role="button"
            class="mark_tooltip"
            v-tooltip.top-center="`${localization('Useful tips and support')}`"
          >
            <img src="@/assets/new-theme/icons/help-circle@2x.png" alt="" />
          </div>
        </div>
      </div>
      <div class="tabs_wrapper pt-3 px-2">
        <div class="step_number_and_questions_number">
          <img
            role="button"
            src="@/assets/new-theme/icons/arrow-narrow-left-active-Button.png"
            alt=""
          />
          <div class="d-flex">
            <span class="mr-2" style="font-size: 24px; font-weight: 500; font-family: Poppins;">
                Step 4 -  Congratulations! your team is created
            </span>
          </div>
        </div>
        <div class="tabs_steps mt-4">
          <span
            v-for="(tab, index) in 4"
            :key="tab"
            class="d-block step"
            :class="{ active: isActive(index) }"
          >
          </span>
        </div>
        
        <div
          class="global_theme_ui_collapse main_profile_personalize_and_collapse pt-4"
        >
          <TeamAnswers />
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TeamForm from "./TeamForm.vue";
import TeamAnswers from "./TeamAnswers.vue";
export default {
  name: "PwpQuestions",
  data() {
    return {
      hideShowSidebar: false,
      getAllStatements: {},
      hideXIcon: false,
      cardItems: [
        {
          src: require("@/assets/new-theme/mail.png"),
          title: "Accept the team invitation",
          description:
            "Ask your team members to accept the invitation and join the team and complete all required steps."
        },
        {
          src: require("@/assets/new-theme/check.png"),
          title: "Check the team status",
          description:
            "Once the team members completed all required steps the team status will changed to In progress status."
        },
        {
          src: require("@/assets/new-theme/pen.png"),
          title: "Add your own statement",
          description:
            "After reviewing what your team members bring and need to a team, write a statement of how you will work with THIS team."
        }
      ],
      tabs: [],
      active: 0,
      activeTabs: [0],
      currentTab: 0,
      team_id: null,
      panel: [`Precision`, `Sequence`, "Technical Reasoning", "Confluence"],
      selected: []
    };
  },
  components:{
    TeamForm,
    TeamAnswers
  },
  computed: {
    ...mapGetters({
      userLoad: ["teams/userLoad"],
      questionTwo: ["teams/questionTwo"],
    }),
    careerMatchStatementsQuestions() {
      return this.$store.getters["user/getCareerMatchStatementsQuestions"];
    },
    mainUserId() {
      return this.auth().id;
    },
    teamId() {
      return this.$route.params.id ? this.$route.params.id : null;
    },
    questionTitle() {
      if(this.questionTwo){
        return Object.keys(this.questionTwo)[0];
      }
    }
  },
  methods: {
    hideAndShowSidebar() {
      this.hideShowSidebar = !this.hideShowSidebar;
      this.hideXIcon = !this.hideXIcon;
    },
    isActive(index) {
      return [0, 1, 2, 3].includes(index);
    },
    
  },
};
</script>

<style lang="scss" scoped>
.main_lci_wrapper {
  width: 100%;

  .render_action {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .main_layout {
    gap: 40px;
  }
  .cards_and_logo {
    background: #fcfcfd;
    max-width: 25%;
    min-width: 25%;
    padding: 20px;
    position: relative;
    .absolute_question_mark {
      position: fixed;
      left: 10px;
      top: 82%;
      &.make_absolute {
        position: absolute;
        bottom: 0;
      }
    }
    .cards_wrapper {
      margin-top: 40px;
    }
  }
  // Tabs
  .tabs_wrapper {
    flex-grow: 1;
    // background: #eee;
    .step_number_and_questions_number {
      display: flex;
      align-items: center;
      gap: 10px;
      .st_number_value{
        color: #101828;
        font-size: 22px;
        font-weight: 500;
      }
      .st_questions_number {
        color: #667085;
        font-size: 14px;
      }
      .st_number_value{
        p{
          font-size: 22px;
          font-weight: 500;
        }
      }
    }
    .tabs_steps {
      display: flex;
      justify-content: space-between;
      gap: 30px;
      .step {
        height: 4px;
        border-radius: 8px;
        background-color: #e9d7fe;
        flex-grow: 1;
        // width: 180px;
        &.active {
          background-color: #7f56d9;
        }
      }
    }
  }
}
// Buttons Next And Previous
.lci_btn {
  background-color: #f2f4f7;
  color: #667085;
  border-radius: 8px;
  padding: 12px 20px;
  border: 1px solid #eaecf0;
  width: 240px;
  &.save {
    background-color: #7f56d9;
    color: #fff;
  }
}
.prev_next {
  background-color: #f2f4f7;
  border-radius: 8px;
  padding: 12px 20px;
  border: 1px solid #eaecf0;
  color: #667085;
  width: 70px;
}
.mark_tooltip {
  border: 1px solid #d0d5dd;
  width: fit-content;
  padding: 12px;
  border-radius: 50%;
}

.main_profile_personalize_and_collapse{
  position: relative;
}

.main_profile_personalize_and_collapse::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  background-size: contain;
  opacity: 0.5; 
  z-index: -1;
  pointer-events: none;
}

.main_profile_personalize_and_collapse {
  background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), 
              url('../../../assets/new-theme/lci/background.png') no-repeat right center;
  background-size: contain;
}
</style>

<style lang="scss">
.main_profile_personalize_and_collapse {
  .el-collapse {
    border: none !important;
  }
  .el-collapse-item__content {
    box-shadow: none !important;
    border: none !important;

    // @media print {
    //   padding-bottom: 0;
    // }
  }
  .el-collapse-item__wrap {
    border-bottom: none !important;
  }
  .el-collapse-item__content {
    padding-bottom: 0;
    font-size: 16px;
    line-height: inherit;
    color: #344054 !important;
  }
  .el-checkbox__inner {
    border: 1px solid #d0d5dd;
    border-radius: 6px;
    height: 20px;
    width: 20px;
  }
  .el-checkbox {
    align-items: flex-start;
    display: flex;
  }
  .el-checkbox__label,
  .el-checkbox is-checked {
    color: #344054 !important;
    font-weight: 400;
    font-size: 16px;

    // max-width: 98%;
    word-break: break-word !important;
    white-space: pre-wrap;
    // word-wrap: break-word;
    white-space: normal;
  }
  .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #6941c6;
    border-color: #6941c6;
  }
  .el-checkbox__inner::after {
    left: 7px;
    top: 4px;
    border-width: 2px;
  }
}
</style>
