<template>
  <div class="w-100">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12 d-flex justify-content-between">
          <div>
            <h4 class="m-0 font-weight-custom-bold">PLC group members</h4>
          </div>
          <div>
            <button class="btn_2" @click="printPlp()">
              <img
                class="img"
                src="@/assets/new-theme/profile/printer.png"
                alt=""
              />
              <span class="ml-1">Print</span>
            </button>
            <button class="btn_2 ml-2" @click="toggleModalShare()">
              <img
                class="img"
                src="@/assets/new-theme/profile/share-07.png"
                alt=""
              />
              <span class="ml-1">Share</span>
            </button>
          </div>
        </div>
        <div class="col-md-12">
          <div class="mt-3">
            <InvitedMembers :detailsPage="'detailsPage'" :groupDetails="groupDetails" @refresh="Refresh"/>
          </div>
        </div>
      </div>
      <div class="row pt-5" v-if="members">
        <div class="col-md-12">
          <div class="the_main_wrapper_for_charts mb-4" v-if="!loadingValue">
        <div class="is_chart_wrapper">
            <!-- v-if="scores.length > 0" -->
          <chart-card
            :chart-data="groupChart"
            :chart-options="barChart.options"
            :chart-responsive-options="barChart.responsiveOptions"
            class="rounded-xl p-2"
            style="background: #FCFCFD;"
          >
            <template slot="header">
              <div class="d-flex">
                <!-- <i class="el-icon el-icon-data-analysis" style="font-size: 30px; margin-right: 7px"></i> -->
                <h4 class="card-title">{{ localization("Group Line Graph") }}</h4>
              </div>
              <p
                class="text-muted mt-2"
              >See on a graph the areas where group members have similarities and differences.</p>
            </template>
          </chart-card>
          <div class="lines_in_chart avoid">
            <span class="is_text_chart">Avoid</span>
          </div>
          <div class="lines_in_chart needed">
            <span class="is_text_chart">As Needed</span>
          </div>
          <div class="lines_in_chart first">
            <span class="is_text_chart">Use First</span>
          </div>
        </div>
        <div class="for_line_graph_members_container mt-2">
          <div
            v-for="(member, index) in team_members"
            :key="member.id"
            class="every_bullet_wrapper"
            :class="`member_${index + 1}`"
          >
            <span class="bullet"></span>
            <span class="text-capitalize">{{ member.first_name }} {{ member.last_name }}</span>
          </div>
          </div>
            <!-- <div class="description_team w-100">
              <p class="team_type">{{ team_details.classification }}</p>
            </div>-->
            <!-- <div class="team_routes_wrapper" :class="{ 'd-flex flex-row-reverse': isRtl() }">
              <router-link class="back_link" to="/teams">
                <i class="fa fa-arrow-left back_icon mr-1"></i>
                {{ localization("Back to team") }}
              </router-link>
              <router-link class="back_link" to="/working-together">
                {{ localization("next") }}
                <i class="fa fa-arrow-right back_icon mr-1"></i>
              </router-link>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Card, Table as LTable } from "src/components/index";
import { mapGetters } from "vuex";
import ServerTable from "@/components/DataTablePagination.vue";
import "element-ui/lib/theme-chalk/display.css";
import { ChartCard, StatsCard } from "src/components/index";
import InvitedMembers from 'src/views/a-new-theme/groups/InvitedMembers.vue';
import Swal from "sweetalert2";
export default {
  name: "TeamDetails",
  data() {
    return {
      groups: null,
      team_members:[],
      groupDetails: null,
      members: null,
      barChart: {
        options: {
          seriesBarDistance: 20,
          showPoint: true,
          labelDirection: "explode",
          chartPadding: 0,
          labelOffset: 20,
          low: 7,
          high: 40,
          showLine: true,
          labelInterpolationFnc: function(value) {
            return `${value.split(" ")[0]}...`;
          },
          height: "350px",
          width: "95%",
          axisX: {
            offset: 30,
            position: "end",
            showLabel: true,
            showGrid: true,
            labelOffset: {
              x: 0,
              y: 5
            }
          },
          axisY: {
            offset: 50,
            position: "start",
            showLabel: true,
            showGrid: true,
            scaleMinSpace: 20,
            onlyInteger: true,
            labelOffset: {
              x: 0,
              y: 0
            }
          }
        },
        responsiveOptions: [
          [
            "screen and (max-width: 640px)",
            {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc(value) {
                  return value[0];
                }
              }
            }
          ]
        ]
      },
    };
  },
  computed: {
    ...mapGetters({
      loadingValue: ["groups/usersLoad"],
      groupMembers: ["groups/groupMembers"],
      searchContactsItems: ["contacts/searchContacts"],
      contactList: ["contacts/contactList"],
      institutionUsersList: ["groups/getInstUsers"]
    }),
    scores() {
      let scores = [];
      this.members?.forEach(element => {
        scores.push([
          element.sequence,
          element.precision,
          element.technical_reasoning,
          element.confluence
        ]);
      });
      return scores;
    },
    groupChart() {
      let data = {};
      data.labels = [
        "Sequence",
        "Precision",
        "Technical Reasoning",
        "Confluence"
      ];
      data.series = this.scores;
      return data;
    },
    groupId(){
      return this.$route.params.id ? this.$route.params.id : null;
    }
  },
  components: {
    Card,
    ServerTable,
    LTable,
    ChartCard,
    StatsCard,
    InvitedMembers
  },
  mounted() {
    this.Refresh();
  },
  methods: {
    Refresh() {
      this.getGroupsMembersList();
      this.$store.dispatch("groups/GetUserGroups").then(_ => {
        this.groups = this.$store.getters["groups/userGroups"];
      });
      this.$store.dispatch("contacts/getSearchContacts");
      this.$store.dispatch("groups/getGroupMembers").then(()=>{
        this.$nextTick(() => {
          const group = this.groupMembers.filter((el) =>{
            return el.id == this.groupId
          })
          this.groupDetails = group[0]
          this.members = this.groupDetails.members
          console.log("_______________________________________________>>>>>  ", this.members);
        })
      });
    },
    getGroupsMembersList() {
      this.$store.dispatch("groups/getGroupMembers");
    },
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-collapse-item__header {
  height: auto;
  cursor: pointer;
  line-height: 38px;
}
.team_routes_wrapper {
  display: flex;
  gap: 10px;
  padding: 20px 15px 0;
  justify-content: space-between;
  flex-wrap: wrap;
  .back_link {
    color: #777;
    background-color: #eee;
    padding: 8px 10px;
    display: block;
    width: fit-content;
    margin: 10px 0;
    border-radius: 8px;
    transition: 0.3s;
    &:hover {
      background-color: #11bfe3;
      color: #fff;
    }
  }
}
// Start Collapse
.wrapper_collapse_for_header {
  display: flex;
  justify-content: space-between;
  width: 95%;
  .head_part_one {
    .part_a {
      display: flex;
      align-items: center;
      gap: 5px;
      color: #777;
      .user_icon {
        &.admin_badge {
          color: #3c3;
        }
      }
    }
    .part_b {
      color: #11bfe3;
    }
  }
  .head_part_two {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}
// LCI Scores
.main-scores-container {
  // @media (min-width: 320px) and (max-width: 991px) {
  //   margin-top: 15px;
  // }
  padding-top: 20px;
  .every-score {
    gap: 7px;
    .score-word {
      color: #777;
    }
    .label {
      padding: 0.5rem;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      text-align: center;
      color: #fff;
      &.precision {
        background-color: #3c3;
      }

      &.technical_reasoning {
        background-color: #f93;
      }

      &.sequence {
        background-color: #39c;
      }

      &.confluence {
        background-color: #c36;
      }
    }
  }
}
.statements_wrapper {
  margin-top: 10px;
  // border-top: 1px solid #ccc;
  .just_for_wrapper_icon {
    // background-color: #eee;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .part_two_icons {
      min-width: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .all_statements {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-grow: 1;
    border-bottom: 1px solid #ccc;
    .every_statement {
      // margin-top: 15px;
      padding: 0.6rem 0.5rem;
      .state_name {
        font-size: 18px;
        // margin-bottom: 8px;
        &.sec {
          color: #11bfe3;
        }
        &.per {
          color: #3c3;
        }
        &.tec {
          color: #f93;
        }
        &.con {
          color: #c36;
        }
      }
      .state_words {
        color: #777;
        line-height: 1.7;
        font-size: 16px;
      }
    }
  }
  .without_statement {
    // margin-top: 20px;
    font-weight: bold;
    .st_words {
      padding: 5px 0 0 0;
      color: #777;
      line-height: 1.7;
      text-align: center;
      font-size: 16px;
    }
  }
}
// Start Introduce Yourself
.introduce_yourself_wrapper {
  margin-top: 10px;
  border-top: 1px solid #ccc;
  .every_intro {
    padding: 0.6rem 0.5rem;
    border-bottom: 1px solid #ccc;
    .question_title {
      font-size: 19px;
      color: #6e6e6e;
      margin-bottom: 8px;
    }
    .patterns_wrapper {
      list-style: none;
      .pattern_title {
        font-size: 18px;
        margin-bottom: 8px;
      }
      .intro_list {
        .every_item {
          color: #777;
          line-height: 1.7;
          font-size: 16px;
          margin-bottom: 8px;
        }
      }
      .for_avoid_etc_words {
        color: #777;
        font-weight: bold;
        font-size: 17px;
        margin-bottom: 8px;
        position: relative;
      }
    }
  }
}
.text_area_desc {
  color: #606266;
  width: 100%;
  border: 1px solid #c0c4cc;
  outline: none;
  transition: 0.3s;
  border-radius: 4px;
  font-size: inherit;
  outline: 0;
  padding: 0 15px;
  height: 150px !important;
  line-height: 1.7 !important;
  padding: 10px 15px;
  resize: none;
  word-break: break-word;

  &:focus {
    border-color: #11bfe3;
  }
  // &.error_input {
  //   border-color: #f56c6c;
  // }
}
.main_invitation_members_wrapper {
  .every_invitation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    .part_i {
      display: flex;
      gap: 5px;
      align-items: center;
      color: #777;
      .email_address {
        opacity: 0.5;
      }
      .email_icon {
        color: #11bfe3;
      }
    }
    .part_ii {
    }
  }
}
//// End Collapse
.team_details_wrapper {
  // Start Description Container
  .description_container {
    padding: 15px 0;
    color: #777;
    .decs_title {
      margin: 0;
    }
    .decs_text {
      margin-top: 15px;
      line-height: 1.7;
    }
  }
  // End Description Container
  .header_contact_wrapping {
    display: flex;
    justify-content: space-between;
    .title_icon {
      color: #11bfe3;
      margin-right: 5px;
    }
  }
  .actions-icons {
    font-size: 14px;
    width: 30px;
    height: 30px;
    box-shadow: 0 0 6px 0 rgba(#000, 0.2), 0 0 5px -2px rgba(#000, 0.1);
    text-align: center;
    line-height: 30px;
    border-radius: 50%;
    cursor: pointer;
    color: #777;
    transition: 0.3s;
    &.delete {
      &:hover {
        background-color: #fb404b;
        color: #fff;
      }
    }
    &.add {
      &:hover {
        background-color: #11bfe3;
        color: #fff;
      }
    }
  }
  .main-members-container {
    padding: 0.5rem 0.3rem;
    margin-top: 0.7rem;
    position: relative;
    color: #777;
    .user-details {
      width: 200px;
      color: #777;
      .part-one {
        display: flex;
        align-items: center;
        gap: 5px;
      }
      .part-two {
        margin-top: 10px;
      }
    }
    .main-scores-container {
      @media (min-width: 320px) and (max-width: 991px) {
        margin-top: 15px;
      }
      .every-score {
        gap: 7px;
        .score-word {
          color: #777;
        }
        .label {
          padding: 0.5rem;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          text-align: center;
          color: #fff;
          &.precision {
            background-color: #3c3;
          }

          &.technical_reasoning {
            background-color: #f93;
          }

          &.sequence {
            background-color: #39c;
          }

          &.confluence {
            background-color: #c36;
          }
        }
      }
    }
    .td-actions {
      gap: 10px;
      @media (min-width: 320px) and (max-width: 991px) {
        position: absolute;
        right: 0;
        top: 15px;
      }
    }
  }
}

.for_statements_details {
  .user_statement_info {
    .name_and_icon {
      display: flex;
      align-items: center;
      gap: 5px;
      .use_icon {
        color: #11bfe3;
        font-size: 18px;
      }
      .use_name {
        font-size: 18px;
        text-transform: capitalize;
      }
    }
    .use_classification {
      margin-top: 8px;
      font-size: 18px;
    }
  }
  .statements_wrapper {
    .all_statements {
      .every_statement {
        margin-top: 15px;
        padding: 0.6rem 0.5rem;
        .state_name {
          font-size: 18px;
          margin-bottom: 8px;
          &.sec {
            color: #11bfe3;
          }
          &.per {
            color: #3c3;
          }
          &.tec {
            color: #f93;
          }
          &.con {
            color: #c36;
          }
        }
        .state_words {
          color: #777;
          line-height: 1.7;
          font-size: 16px;
        }
      }
    }
    .without_statement {
      margin-top: 20px;
      .st_words {
        // padding: 5px 0;
        color: #777;
        line-height: 1.7;
        text-align: center;
        font-size: 16px;
      }
    }
  }
}

.the_main_wrapper_for_charts {
  background: #fff;
  padding-bottom: 1rem;
  .card-header {
    border-radius: 16px !important;
  }
  .card {
    margin-bottom: 0;
    border: unset;
  }
  .card-body {
    // border: transparent !important;
  }
  .description_team {
    background-color: #fff;
    padding: 0 0.5rem;
    text-align: center;
    .team_type {
      color: #777;
      margin: 0;
      line-height: 1.7;
      font-size: 17px;
    }
  }

  .is_chart_wrapper {
    position: relative;
  }
  .lines_in_chart {
    position: relative;
    bottom: 240px;
    padding: 0 5px;
    // background: #eee;
    &::before {
      position: absolute;
      content: "";
      width: 87%;
      left: 5%;
      bottom: 0;
      border: 1px dashed #11bfe3;
    }
    &.avoid {
      &::before {
        bottom: -50px;
      }
      .is_text_chart {
        position: absolute;
        bottom: -105px;
        font-size: 12px;
      }
    }
    &.needed {
      bottom: 324px;
      &::before {
        bottom: -75px;
      }
      .is_text_chart {
        position: absolute;
        bottom: -105px;
        font-size: 12px;
      }
    }
    &.first {
      bottom: 443px;
      &::before {
        display: none;
      }
      .is_text_chart {
        position: absolute;
        bottom: -130px;
        font-size: 12px;
      }
    }
  }
}
// Just For Showing Close Icon In Show Statements Dialog
::v-deep .el-dialog__close {
  // display: block !important;
  // cursor: pointer;
}
::v-deep .el-dialog {
  @media (min-width: 320px) and (max-width: 480px) {
    width: 90% !important;
  }
  @media (min-width: 480px) and (max-width: 767px) {
    width: 70% !important;
  }
}
.dialog_footer {
  display: flex;
  justify-content: end;
  align-items: center;
  @media (min-width: 320px) and (max-width: 991px) {
    justify-content: center;
  }
}
.btn_2{
  background: transparent;
  border: unset;
  color: #6941C6;
}
</style>

<style lang="scss">
.team_details_wrapper {
  .card .ct-label.ct-vertical.ct-start {
    width: 38px !important;
  }
  .for_line_graph_members_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px 0;
    flex-wrap: wrap;
    .every_bullet_wrapper {
      display: flex;
      align-items: center;
      gap: 3px;
      margin-bottom: 20px;
      .bullet {
        background: #eee;
        width: 15px;
        height: 15px;
        border-radius: 50%;
      }
      &.member_1 {
        .bullet {
          background-color: black;
        }
      }
      &.member_2 {
        .bullet {
          background-color: #fe9d52;
        }
      }
      &.member_3 {
        .bullet {
          background-color: #4198d7;
        }
      }
      &.member_4 {
        .bullet {
          background-color: brown;
        }
      }
      &.member_5 {
        .bullet {
          background-color: #7a61ba;
        }
      }
    }
  }
  .ct-series-a .ct-line,
  .ct-series-a .ct-point {
    stroke: black;
  }
  .ct-series-b .ct-line,
  .ct-series-b .ct-point {
    stroke: #fe9d52;
  }
  .ct-series-c .ct-line,
  .ct-series-c .ct-point {
    stroke: #4198d7;
  }
  .ct-series-d .ct-line,
  .ct-series-d .ct-point {
    stroke: brown;
  }
  .ct-series-e .ct-line,
  .ct-series-e .ct-point {
    stroke: #7a61ba;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    .ct-label.ct-horizontal.ct-end {
      font-size: 11px !important;
    }
  }
  .is_chart_wrapper {
    .ct-chart-line {
      width: 90% !important;
    }
  }
}

.el-button--primary.is-plain:focus,
.el-button--primary.is-plain:hover {
  background-color: #118fe3 !important;
  color: #fff !important;
}
.twp {
  background: url("../../../assets/new-theme/lci/background.png") no-repeat;
  background-size: 80%;
  background-position: 600px center;
  position: relative;
  .action-twp {
    position: absolute;
    top: 0;
    right: 0;
    padding: 15px;
  }

  .el-button {
    background: transparent;
  }
}

.el-tabs--border-card, .el-tabs__content{
  border: unset !important;
}

.el-tabs__nav-scroll{
  padding: 10px;
}

.el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active{
  border-top-color: #DCDFE6;
  border-bottom-color: #DCDFE6;
  border-radius: 7px;
}
.team_members{
  .el-table .cell{
    overflow: unset !important;
  }
}
</style>
