var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{staticClass:"user_modal statement_modal custom_share_modal",attrs:{"visible":_vm.showHideModalShare,"top":"4vh","width":"35%","show-close":false},on:{"update:visible":function($event){_vm.showHideModalShare=$event}}},[_c('div',{staticClass:"user_modal_type w-100"},[_c('img',{staticClass:"img",attrs:{"src":require("@/assets/new-theme/profile/share-07.png"),"alt":""}}),_c('div',[_c('h4',{staticClass:"modal_title mb-0"},[_vm._v(" "+_vm._s(_vm.title)+" ")])]),_c('div',{staticClass:"description_type"},[_c('p',{staticClass:"text_type mb-0"},[_vm._v(" "+_vm._s(_vm.description)+" ")])]),_c('div',[_c('div',{staticClass:"form_share"},[_c('h4',{staticClass:"modal_title mb-1"},[_vm._v(" Email address ")]),_c('el-form',{ref:"shareForm",attrs:{"model":_vm.formData,"rules":_vm.share_profile_rules}},_vm._l((_vm.formData.emails),function(email,index){return _c('div',{key:index,staticClass:"each_emil_wrapper"},[_c('el-form-item',{attrs:{"rules":[
                {
                  required: true,
                  message: 'Please input email address',
                  trigger: 'blur'
                },
                {
                  type: 'email',
                  message: 'Please input correct email address',
                  trigger: ['blur', 'change']
                }
              ],"prop":'emails.' + index}},[_c('div',{staticClass:"email_wrapper"},[_c('img',{staticClass:"img",attrs:{"src":require("@/assets/new-theme/icons/mail.png"),"alt":"mail"}}),_c('el-input',{attrs:{"autocomplete":"off","placeholder":"email@plc.com"},model:{value:(_vm.formData.emails[index]),callback:function ($$v) {_vm.$set(_vm.formData.emails, index, $$v)},expression:"formData.emails[index]"}})],1),(_vm.formData.emails.length > 1)?_c('img',{staticClass:"delete_icon",attrs:{"src":require("@/assets/new-theme/delete-button-table.png"),"alt":"delete"},on:{"click":function($event){return _vm.removeEmail(index)}}}):_vm._e()])],1)}),0),_c('div',{staticClass:"your_own_statement ",on:{"click":function($event){return _vm.addEmail()}}},[_c('img',{attrs:{"role":"button","src":require("@/assets/new-theme/profile/plus.png"),"alt":"plus"}}),_c('span',{attrs:{"role":"button"}},[_vm._v("Add another")])])],1)]),_c('div',{staticClass:"lci_modal_buttons mt-4"},[_c('button',{staticClass:"lci_btn_modal_cancel ",on:{"click":function($event){return _vm.close()}}},[_vm._v(" Cancel ")]),_c('button',{staticClass:"lci_btn save",on:{"click":function($event){return _vm.submitShare()}}},[_vm._v(" Share ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }