<template>
  <div class="w-100 m-0 p-0">
    <div class=" mt-2 ">
      <div class="main_table">
        <TableHeaderSection
          @toggleModal="ToggleTaskModal('add')"
          title="Team members"
          text="See all team members' learning patterns or add new members to your team."
          buttonText="Add new member"
          :total="$store.getters['user/membersCount']+' members'"
        ></TableHeaderSection>
        <div class="header_container just_custom_table_tasks">
          <CustomTable
            :count="team_members.length"
            :DataItems="team_members"
            @Refresh="refresh"
            @reset="filter = {}"
            :filter="filter"
            :title="'Tasks'"
            hidden_export_import
            admin_permission="admin_tasks"
            edit_permission="add_tasks"
            class="team_members"
            @selection="selections"
            :isGroup="detailsPage=='detailsPage' ? false : true"
          >
            <template #columns>
              <el-table-column type="selection" width="40"></el-table-column>
              <el-table-column prop="first_name" :label="`${localization('Member Name')}`">
                <template slot-scope="props">
                  <div class="d-flex ">
                    <h5 class="m-0">{{ props.row.first_name }} </h5>
                    <h5 class="m-0">{{ props.row.last_name }}</h5>
                  </div>
                  <div>
                    <span>{{ props.row.classification }}</span>
                  </div>
                </template>
              </el-table-column>
              <!-- <el-table-column prop="status" :label="`${localization('Status')}`">
                <template slot-scope="props">
                  <span>{{ props.row.status }}</span>
                </template>
              </el-table-column> -->
              <el-table-column prop="sequence" :label="`${localization('sequence')} (S)`">
                <template slot-scope="props">
                  <span class="text-white bg-info rounded-pill px-3 py-2">{{ props.row.sequence }} - {{ props.row.user_pattern_classification.sequence }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="precision" :label="`${localization('Precision')} (P)`">
                <template slot-scope="props">
                  <span class="text-white bg-success rounded-pill px-4 py-2">{{ props.row.precision }} - {{ props.row.user_pattern_classification.precision }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="technical_reasoning " :label="`${localization('Technical Reasoning ')} (T)`">
                <template slot-scope="props">
                  <span class="text-white bg-warning rounded-pill px-4 py-2">{{ props.row.technical_reasoning }} - {{ props.row.user_pattern_classification.technical_reasoning }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="confluence" :label="`${localization('Confluence')} (C)`">
                <template slot-scope="props">
                  <span class="text-white bg-danger rounded-pill px-4 py-2">{{ props.row.confluence }} - {{ props.row.user_pattern_classification.confluence }}</span>
                </template>
              </el-table-column>
              <el-table-column prop width="80">
                <template slot-scope="scope">
                  <div class="td_actions flex justify-content-end">
                    <!-- show task button  -->
                    <router-link
                      v-if="hasPermission('show_tasks') && detailsPage=='detailsPage'"
                      :to="`#`"
                      v-tooltip.top-center="
                          `${localization('Show')}`
                        "
                      class="btn btn-info btn-link btn-xs px-1 m-0"
                    >
                      <img src="@/assets/new-theme/eye.png" alt="eye" />
                    </router-link>
                    <span class="btn btn-warning btn-simple btn-link px-1 m-0"
                      v-tooltip.top-center="
                        `${localization('Delete')}`
                      " 
                      @click="delete_member_from_team(scope.row.id)"
                      v-if="scope.row.user_status == 1"
                    >

                      <img src="@/assets/new-theme/trash.png" alt="delete" />
                    </span>
                    <span class="btn btn-warning btn-simple btn-link px-1 m-0"
                      v-tooltip.top-center="
                        `${localization('Restore')}`
                      " 
                      @click="retriveTeamMember(scope.row.id)"
                      v-else
                    >
                      <img src="@/assets/images/icons/reverse-left.png" alt="delete" />
                    </span>
                  </div>
                </template>
              </el-table-column>
            </template>
          </CustomTable>
        </div>
        <div class="mt-3 d-flex justify-content-center mb-5">
            <!-- <button
              @click="prevTab"
              class="prev_next"
            >
              <img
                src="@/assets/new-theme/icons/arrow-narrow-left-active-Button.png"
                alt="edit icon"
              />
            </button> -->
            <button
              @click="$router.push({name: 'TeamsView'})"
              class="lci_btn save mx-4"
              v-if="detailsPage != 'detailsPage'"
            >
              Back to teams
            </button>

            <!-- <button
              @click="nextTab"
              class="prev_next"
            >
              <img
                src="@/assets/new-theme/icons/arrow-narrow-right-Disable-Button.png"
                alt="edit icon"
              />
            </button> -->
          </div>
      </div>
    </div>
    <el-dialog
      :title="`${localization('Add Members')}`"
      :visible.sync="addMemberModal"
      width="70%"
      custom-class="task-modal global_modal rounded-xl mt-0"
      @close="resetForm"
      :close-on-click-modal="false"
      class="dialog-center"
    >
      <div class="custom_modal_header">
        <div class="images">
          <img src="@/assets/new-theme/join.png" alt="file" />
          <img
            @click="resetForm"
            class="close_img"
            src="@/assets/new-theme/x-close-modal.png"
            alt="file"
          />
        </div>
        <h3 class="title font-weight-bold mt-3">
          Add Members
        </h3>
        <!-- <p class="description">
          Please enter the code that shared with you by email, if you can’t find the code check the spam file or try contact your team admin. 
        </p> -->
      </div>
      <el-form :rules="rules" ref="teamRef">
        <el-form-item :label="`${localization('Members')}`">
          <el-select
            ref="users"
            :placeholder="`${localization('Select Members')}`"
            no-data-text="Type Name"
            style="width: 100%"
            v-model="selected_team_members"
            filterable
            multiple
            remote
            reserve-keyword
            :remote-method="search"
            :loading="loadingValue"
          >
            <el-option
              v-for="user in contactList"
              :key="user.id"
              :label="user.first_name + ' ' + user.last_name"
              :value="user.id"
              :disabled="selected_team_members.length == 5"
            >
              <span style="color: #8492a6; font-size: 13px">
                <i class="el-icon-user-solid"></i>
                {{ user.first_name + " " + user.last_name }}
              </span>
            </el-option>
          </el-select>
        </el-form-item>
        <div>
          {{ localization("Reinvite members by email") }}
        </div>
        <el-form-item
          :label="`${localization('Email')}`"
          style="width: 100%"
          prop="questions_options"
        >
          <el-select
            v-model="emails"
            no-data-text="Type Email"
            multiple
            filterable
            allow-create
            default-first-option
            class=""
            style="width: 100%"
            :placeholder="`${localization('Type Email')}`"
          >
          </el-select>
        </el-form-item>
        <el-form-item label="Institution Users" prop="">
          <el-select
            v-model="selected_institutions_users"
            :placeholder="`${localization('Please select user')}`"
            style="width: 100%"
            multiple
            filterable
          >
            <el-option
              v-for="item in institutionUsersList"
              :key="item.id"
              :label="item.first_name + ' ' + item.last_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <!-- Footer Buttons -->
      <div class="lci_modal_buttons mt-5">
        <button @click="resetForm" class="lci_btn_modal_cancel ">
          Cancel
        </button>
        <button @click="submitForm" class="lci_btn save lci_btn_modal_cancel">
          Add
        </button>
      </div>
    </el-dialog>
  </div>
</template>
  
<script>
import { Card } from "src/components/index";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import { ChartCard, StatsCard } from "src/components/index";
export default {
  data() {
    return {
    
    };
  },
  components: {
    Card,
    ChartCard,
    StatsCard
  },
  computed: {
    ...mapGetters({
      loadingValue: ["user/usersLoad"],
      team_details: ["user/get_team_details"],
      contactList: ["contacts/contactList"],
      team_members: ["user/get_team_members"],
      invited_team_members: ["user/get_invited_team_members"],
      institutionUsersList: ["groups/getInstUsers"]
    }),
    teamId() {
      return this.$route.params.id ? this.$route.params.id : null;
    },
  },
  props:{
    detailsPage: {
      type: String,
      default: '',
    },
  },
  data(){
    return {
      selected: [],
      filter:{},
      rules: {
        name: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ]
      },
      addMemberModal: false,
      selected_team_members: [],
      emails: [],
      selected_institutions_users: []
    }
  },
  mounted() {
    this.get_team_details();  
  },
  methods: {
    refresh(query){

      this.$store.dispatch("user/every_team_members", this.teamId  );
    },
    get_team_details() {
      this.$store.dispatch("user/every_team_members", this.teamId);
    },
    selections(selections) {
      this.selected = Array.from(selections, el => el.id);
    },
    ToggleTaskModal(){
      this.addMemberModal = true
    },
    resetForm(){
      this.selected_team_members = [];
      this.addMemberModal = false
    },
    submitForm(){
      let selectedMem = [
        ...this.selected_team_members,
        ...this.selected_institutions_users
      ];
      this.$store
        .dispatch("user/invite_one_to_team", {
          id: this.teamId,
          users: selectedMem,
          emails: this.emails
        })
        .then(() => {
          this.addMemberModal = false
          this.refresh()
          Swal.fire({
            title: "",
            icon: "success",
            text: `${this.localization("Done")}`,
            confirmButtonText: `${this.localization("Ok")}`,
            type: "success",
            confirmButtonClass: "btn btn-success ",
            buttonsStyling: false
          });
      });
    },
    search(query) {
      this.usersRoles(query);
    },
    usersRoles(search = "", limit = 10) {
      this.$store
        .dispatch("contacts/GetContactsList", {
          search_text: search
        })
        .then(() => {
          this.search_group = this.$store.getters["contacts/contactList"];
        });
    },
    delete_member_from_team(memberId) {
      Swal.fire({
        title: "",
        text: `${this.localization("Are You Sure")}`,
        type: "warning",
        icon: "error",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success ",
        cancelButtonClass: "btn btn-danger ",
        confirmButtonText: `${this.localization("Delete")}`,
        cancelButtonText: `${this.localization("Cancel")}`,
        buttonsStyling: false
      }).then(date => {
        if (date.isConfirmed) {
          this.$store
            .dispatch("teams/removeTeamMember", {
              teamId: this.teamId,
              id: memberId
            })
            .then(_ => {
              this.refresh();
              Swal.fire({
                title: "",
                icon: "success",
                type: "success",
                text: `${this.localization("Deleted")}`,
                confirmButtonText: `${this.localization("Ok")}`,
                confirmButtonClass: "btn btn-success ",
                buttonsStyling: false
              });
            });
        }
      });
    },
    retriveTeamMember(memberId) {
      Swal.fire({
        title: "",
        text: `${this.localization("Are You Sure")}`,
        type: "warning",
        icon: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success ",
        cancelButtonClass: "btn btn-danger ",
        confirmButtonText: `${this.localization("Restore")}`,
        cancelButtonText: `${this.localization("Cancel")}`,
        buttonsStyling: false
      }).then(date => {
        if (date.isConfirmed) {
          this.$store
            .dispatch("teams/retriveTeamMember", {
              teamId: this.teamId,
              id: memberId
            })
            .then(_ => {
              this.refresh();
              Swal.fire({
                title: "",
                icon: "success",
                type: "success",
                text: `${this.localization("Restored")}`,
                confirmButtonText: `${this.localization("Ok")}`,
                confirmButtonClass: "btn btn-success ",
                buttonsStyling: false
              });
            });
        }
      });
    },
  }
};
</script>
<style scoped lang="scss">
::v-deep .el-tabs--border-card > .el-tabs__header {
  border-radius: 10px !important;
}
.alert-custom {
  background-color: #fffaf0; /* Light background similar to your image */
  border: 1px solid #ffd580; /* Border color to match */
  color: #c35a00; /* Text color to match the orange */
  border-radius: 10px;
}

.alert-custom i {
  color: #c35a00; /* Icon color */
  font-size: 20px;
}

.alert-custom a {
  color: #c35a00;
  text-decoration: none;
}

.alert-custom a:hover {
  text-decoration: underline;
}

::v-deep .el-tabs--border-card, .el-tabs__content{
  border: unset !important;
}

::v-deep .el-tabs__nav-scroll{
  padding: 10px !important;
}

::v-deep .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active{
  border-top-color: #DCDFE6 !important;
  border-bottom-color: #DCDFE6 !important;
  border-radius: 7px !important;
}

::v-deep .team_members{
  .el-table .cell{
    overflow: unset !important;
  }
}

::v-deep.el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active{
  color: #344054;
}

::v-deep .el-tabs--border-card{
  box-shadow: unset !important;
}

::v-deep .el-tabs--border-card>.el-tabs__header{
  border-bottom: unset !important;
}

.lci_btn {
  background-color: #f2f4f7;
  color: #667085;
  border-radius: 8px;
  padding: 12px 20px;
  border: 1px solid #eaecf0;
  width: 240px;
  &.save {
    background-color: #7f56d9;
    color: #fff;
  }
}

.dialog-center{
  top: 50% !important; 
  left: 50%;           
  transform: translate(-50%, -60%) !important; 
  overflow: unset !important;
}

 .swal2-icon .swal2-info .swal2-icon-content{
    display: flex;
    align-items: center;
    font-size: 0.8em !important;
}
</style>