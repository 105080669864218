<template>
  <div class="col just_for_print_group">
    <!-- <div class="">
      <button @click="printGroups">printGroups</button>
    </div> -->
    <div v-if="hasPermission('access_groups')">
      <div v-loading="loadingValue">
        <!--Start Card Part Two For Restored Cards  -->
        <card>
          <template #header>
            <div
              class="header_contact_wrapping d-flex groups-header justify-content-between w-100 align-items-center"
              :class="{ 'flex-row-reverse': auth().language == 2 }"
            >
              <h4
                class="card-title contact_title"
                :class="{ is_rtl: auth().language == 2 }"
              >
                <i class="nc-icon nc-paper-2 group-title-icon"></i>
                <span class="mr-1 ml-1">{{ localization("Groups") }}</span>
              </h4>
              <div class="is_wrap_just_print">
                <button class="btn_just_print">
                  <i
                    @click="printGroups()"
                    class="fa fa-print d-block actions-icons add"
                  ></i>
                </button>
                <l-button
                  type="info"
                  @click="openSearchGroupDialog(1, {})"
                  wide
                  class="d-block add_contact_buttons"
                  >{{ localization("Add New Group") }}
                </l-button>
              </div>
            </div>
          </template>
          <el-collapse accordion>
            <el-collapse-item
              v-for="member in groupMembers"
              :key="member.id"
              :title="member.name"
              :name="member.id"
            >
              <!-- @click="getGroupItem(member)" -->
              <template slot="title">
                <div
                  class="main-accordion-header  d-flex justify-content-between align-items-center"
                  :class="{ 'flex-row-reverse': isRtl() }"
                >
                  <h5
                    :class="{ 'flex-row-reverse': isRtl() }"
                    class="member-name d-flex align-items-center "
                    style="gap:10px"
                  >
                    <i class="fa fa-users" style="font-size: 18px"></i>
                    <span class="d-block text-capitalize">{{
                      member.name
                    }}</span>
                  </h5>
                  <div class="td-actions d-flex actions-icons-container">
                    <span
                      v-if="member.members"
                      class=" actions-icons members-group-numbers d-block"
                      >{{ member.members.length }}</span
                    >

                    <i
                      v-tooltip.top-center="
                        `${localization('Add a new member')}`
                      "
                      @click="openAdditionalMembersDialog(member.id)"
                      class="fa fa-plus d-block actions-icons add"
                    ></i>
                    <i
                      v-tooltip.top-center="
                        `${localization('Edit group name')}`
                      "
                      @click="openSearchGroupDialog(2, member)"
                      class="fa fa-edit  d-block actions-icons edit"
                    ></i>

                    <i
                      v-if="member.members.length >= 1"
                      v-tooltip.top-center="`${localization('Export group')}`"
                      @click="export_groups(member.id, member.name)"
                      class="fa fa-arrow-down  d-block actions-icons edit export"
                    ></i>
                    <i
                      v-tooltip.top-center="`${localization('Delete group')}`"
                      @click="remove(member.id)"
                      class="fa fa-times  d-block actions-icons delete"
                    ></i>
                  </div>
                </div>
              </template>
              <!-- // Start server table in large screens   -->
              <server-table
                class="server_table_for_big_screens"
                :DataItems="member.members"
                :count="groupMembers.length"
                @Refresh="getGroupsMembersList"
                :loading="loadingValue"
                :title="`${localization('Groups')}`"
                admin_permission="admin_users"
                edit_permission="add_tasks"
                :hidden_export_import="true"
                :action="false"
                :isGroup="true"
              >
                <template #columns>
                  <el-table-column
                    sortable
                    prop="first_name"
                    :label="`${localization('First Name')}`"
                    width="130px"
                    class="for_print_column"
                  >
                    <template slot-scope="scope">
                      <div class=" ">
                        <div class="">
                          <router-link :to="`/user-details/${scope.row.id}`">
                            {{ scope.row.first_name }}
                          </router-link>
                        </div>
                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column
                    sortable
                    prop="last_name"
                    :label="`${localization('Last Name')}`"
                    width="120px"
                  >
                    <template slot-scope="scope">
                      <div class=" ">
                        <div class="">
                          {{ scope.row.last_name }}
                        </div>
                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column
                    prop="email"
                    :label="`${localization('Email')}`"
                    width="230px"
                  >
                    <template slot-scope="scope">
                      <div class="d-flex part-one">
                        <span class="d-block user-email">{{
                          scope.row.email
                        }}</span>
                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column
                    prop="patterns"
                    :label="`${localization('Patterns')}`"
                  >
                    <template slot-scope="scope">
                      <!-- User Score scope.row.can == true &&   -->
                      <div
                        class="flex-grow-1 flex-wrap d-flex justify-content-between align-items-center text-capitalize main-scores-container"
                      >
                        <!-- Sequence  -->
                        <div class="d-flex align-items-center every-score">
                          <span class="d-block score-word">{{
                            localization("Sequence shortcut")
                          }}</span>
                          <span class="label sequence d-block score-degre">
                            {{
                              scope.row.sequence != null
                                ? scope.row.sequence
                                : 0
                            }}
                          </span>
                        </div>

                        <!-- Precision  -->
                        <div class="d-flex align-items-center every-score">
                          <span class="d-block score-word">{{
                            localization("precision shortcut")
                          }}</span>
                          <span class="label precision d-block score-degre">
                            {{
                              scope.row.precision != null
                                ? scope.row.precision
                                : 0
                            }}
                          </span>
                        </div>

                        <!-- Technical Reasoning  -->
                        <div class="d-flex align-items-center every-score">
                          <span class="d-block score-word">{{
                            localization("Technical Reasoning shortcut")
                          }}</span>
                          <span
                            class="label technical_reasoning d-block score-degre"
                          >
                            {{
                              scope.row.technical_reasoning != null
                                ? scope.row.technical_reasoning
                                : 0
                            }}
                          </span>
                        </div>

                        <!-- Confluence  -->
                        <div class="d-flex align-items-center every-score">
                          <span class="d-block score-word">{{
                            localization("Confluence shortcut")
                          }}</span>
                          <span class="label confluence d-block score-degre">
                            {{
                              scope.row.confluence != null
                                ? scope.row.confluence
                                : 0
                            }}
                          </span>
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="" label="_" width="100px">
                    <template slot-scope="scope">
                      <div
                        class="td-actions d-flex justify-content-center  actions-icons-container"
                      >
                        <i
                          v-tooltip.top-center="
                            `${localization('Delete member')}`
                          "
                          @click="
                            removeMemberFromGroup(member.id, scope.row.id)
                          "
                          class="fa fa-times  d-block for-delete-member actions-icons delete"
                        ></i>
                      </div>
                    </template>
                  </el-table-column>
                </template>
              </server-table>
              <!-- // End server table in large screens  -->

              <!-- //* Start from mobile to (100px) screens -->
              <server-table
                class="server_table_for_mobile_screen"
                :DataItems="member.members"
                :count="groupMembers.length"
                @Refresh="getGroupsMembersList"
                :loading="loadingValue"
                :title="`${localization('Groups')}`"
                admin_permission="admin_users"
                edit_permission="add_tasks"
                :hidden_export_import="true"
                :action="false"
                :isGroup="true"
              >
                <template #columns>
                  <el-table-column
                    sortable
                    prop="first_name"
                    :label="`${localization('First Name')}`"
                  >
                    <template slot-scope="scope">
                      <div class=" ">
                        <div class="">
                          <router-link :to="`/user-details/${scope.row.id}`">
                            {{ scope.row.first_name }}
                          </router-link>
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="last_name"
                    sortable
                    :label="`${localization('Last Name')}`"
                  >
                  </el-table-column>
                  <el-table-column prop="" label="_">
                    <template slot-scope="scope">
                      <div
                        class="td-actions d-flex justify-content-center  actions-icons-container"
                      >
                        <i
                          v-tooltip.top-center="
                            `${localization('Delete member')}`
                          "
                          @click="
                            removeMemberFromGroup(member.id, scope.row.id)
                          "
                          class="fa fa-times  d-block for-delete-member actions-icons delete"
                        ></i>
                      </div>
                    </template>
                  </el-table-column>
                </template>
              </server-table>
              <!-- //* End from mobile to (100px) screens -->

              <!-- //? Start just mobile  screens -->
              <server-table
                class="server_table_just_for_mobile_screen d-none"
                :DataItems="member.members"
                :count="groupMembers.length"
                @Refresh="getGroupsMembersList"
                :loading="loadingValue"
                :title="`${$t('groups.Groups')}`"
                admin_permission="admin_users"
                edit_permission="add_tasks"
                :hidden_export_import="true"
                :action="false"
                :isGroup="true"
              >
                <template #columns>
                  <el-table-column
                    sortable
                    prop="first_name"
                    :label="`${localization('Name')}`"
                  >
                    <template slot-scope="scope">
                      <div class=" ">
                        <div class="">
                          <router-link :to="`/user-details/${scope.row.id}`">
                            {{
                              scope.row.first_name + " " + scope.row.last_name
                            }}
                          </router-link>
                          <span class="d-block user-email">{{
                            scope.row.email
                          }}</span>
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="" label="_" width="50px">
                    <template slot-scope="scope">
                      <div
                        class="td-actions d-flex justify-content-center  actions-icons-container"
                      >
                        <i
                          v-tooltip.top-center="
                            `${localization('Delete member')}`
                          "
                          @click="
                            removeMemberFromGroup(member.id, scope.row.id)
                          "
                          class="fa fa-times  d-block for-delete-member actions-icons delete"
                        ></i>
                      </div>
                    </template>
                  </el-table-column>
                </template>
              </server-table>
              <!-- //? End just mobile  screens -->

              <l-table :data="member.members" class="mt-4 d-none">
                <template slot-scope="{ row }">
                  <!-- Large Screens view  -->
                  <div
                    class="d-none d-lg-flex justify-content-between align-items-center w-100 shadow-sm  my-2 main-members-container"
                  >
                    <div class="user-details">
                      <div class="d-flex part-one">
                        <i class="fa fa-user user-icon d-block"></i>

                        <span class="d-block user-name">
                          <router-link :to="`/user-details/${row.id}`">
                            {{ row.first_name + " " + row.last_name }}
                          </router-link>
                        </span>
                      </div>
                      <div class="d-flex part-one">
                        <i class="fa fa-envelope user-icon d-block"></i>
                        <span class="d-block user-name">{{ row.email }}</span>
                      </div>
                    </div>
                    <!-- User Score  -->
                    <div
                      class="flex-grow-1 flex-wrap d-flex justify-content-around align-items-center text-capitalize main-scores-container-sm main-scores-container"
                    >
                      <!-- Sequence  -->
                      <div class="d-flex align-items-center every-score">
                        <span class="d-block score-word">{{
                          $t("patterns.S")
                        }}</span>
                        <span class="label sequence d-block score-degre">
                          {{ row.sequence != null ? row.sequence : 0 }}
                        </span>
                      </div>
                      <!-- Precision  -->
                      <div class="d-flex align-items-center every-score">
                        <span class="d-block score-word">{{
                          $t("patterns.P")
                        }}</span>
                        <span class="label precision d-block score-degre">
                          {{ row.precision != null ? row.precision : 0 }}
                        </span>
                      </div>
                      <!-- Technical Reasoning  -->
                      <div class="d-flex align-items-center every-score">
                        <span class="d-block score-word">{{
                          $t("patterns.TR")
                        }}</span>
                        <span
                          class="label technical_reasoning d-block score-degre"
                        >
                          {{
                            row.technical_reasoning != null
                              ? row.technical_reasoning
                              : 0
                          }}
                        </span>
                      </div>
                      <!-- Confluence  -->
                      <div class="d-flex align-items-center every-score">
                        <span class="d-block score-word">{{
                          $t("patterns.C")
                        }}</span>
                        <span class="label confluence d-block score-degre">
                          {{ row.confluence != null ? row.confluence : 0 }}
                        </span>
                      </div>
                    </div>
                    <!-- Admin Actions  -->
                    <div
                      class="td-actions d-flex justify-content-center  actions-icons-container"
                    >
                      <i
                        v-tooltip.top-center="`${$t('groups.Delete member')}`"
                        @click="removeMemberFromGroup(member.id, row.id)"
                        class="fa fa-times  d-block for-delete-member actions-icons delete"
                      ></i>
                    </div>
                  </div>
                  <!-- Medium & Small Screens view  -->
                  <div
                    class="d-sm-flex d-lg-none justify-content-between align-items-center w-100 shadow-sm  main-members-container"
                  >
                    <!-- info and actions conatainer -->
                    <div class="d-flex justify-content-between">
                      <!-- user info  -->
                      <div class="user-details">
                        <div class="d-flex part-one">
                          <i class="fa fa-user user-icon d-block"></i>
                          <span class="d-block user-name">
                            <router-link :to="`/user-details/${row.id}`">
                              {{ row.first_name + " " + row.last_name }}
                            </router-link>
                          </span>
                        </div>
                        <div class="d-flex part-one">
                          <i class="fa fa-envelope user-icon d-block"></i>
                          <span class="d-block user-name">{{ row.email }}</span>
                        </div>
                      </div>
                    </div>
                    <div
                      class="flex-grow-1 flex-wrap d-flex justify-content-between align-items-center text-capitalize main-scores-container"
                    >
                      <!-- Sequence  -->
                      <div class="d-flex align-items-center every-score">
                        <span class="d-block score-word">{{
                          $t("patterns.S")
                        }}</span>
                        <span class="label sequence d-block score-degre">
                          {{ row.sequence != null ? row.sequence : 0 }}
                        </span>
                      </div>
                      <!-- Precision  -->
                      <div class="d-flex align-items-center every-score">
                        <span class="d-block score-word">{{
                          $t("patterns.P")
                        }}</span>
                        <span class="label precision d-block score-degre">
                          {{ row.precision != null ? row.precision : 0 }}
                        </span>
                      </div>
                      <!-- Technical Reasoning  -->
                      <div class="d-flex align-items-center every-score">
                        <span class="d-block score-word">{{
                          $t("patterns.TR")
                        }}</span>
                        <span
                          class="label technical_reasoning d-block score-degre"
                        >
                          {{
                            row.technical_reasoning != null
                              ? row.technical_reasoning
                              : 0
                          }}
                        </span>
                      </div>
                      <!-- Confluence  -->
                      <div class="d-flex align-items-center every-score">
                        <span class="d-block score-word">{{
                          $t("patterns.C")
                        }}</span>
                        <span class="label confluence d-block score-degre">
                          {{ row.confluence != null ? row.confluence : 0 }}
                        </span>
                      </div>
                    </div>

                    <div
                      class="td-actions d-flex justify-content-center  actions-icons-container"
                    >
                      <i
                        v-tooltip.top-center="`${$t('groups.Delete member')}`"
                        @click="removeMemberFromGroup(member.id, row.id)"
                        class="fa fa-times  d-block for-delete-member actions-icons delete"
                      ></i>
                    </div>
                  </div>
                </template>
              </l-table>
            </el-collapse-item>
          </el-collapse>
        </card>
        <!--End Card Part Two For Restored Cards  -->
      </div>

      <!--// Start For Restored Groups Dialog  -->
      <el-dialog
        :title="
          type == 1
            ? `${localization('Add New Group')}`
            : `${localization('Edit group name')}`
        "
        :visible.sync="search_group_dialog"
        width="50%"
        top="4vh"
        :class="{ 'text-right add_group_with_rtl': isRtl() }"
      >
        <el-form :model="newGroupMember" :rules="rules" ref="addGroup">
          <el-form-item
            :label="`${localization('Name')}`"
            prop="name"
            :rules="[
              {
                required: true,
                message: 'Please this field is required',
                trigger: 'change'
              }
            ]"
          >
            <el-input
              :placeholder="`${localization('Name')}`"
              v-model="newGroupMember.name"
            ></el-input>
          </el-form-item>
        </el-form>
        <div
          slot="footer"
          class="
            dialog-footer
            dialog_footer
          "
        >
          <el-button @click="Refresh" class="text-center d-block ">{{
            localization("Cancel")
          }}</el-button>
          <el-button
            @click="saveNewGroupMembers"
            class="
              text-center
              d-block
              text-capitalize
            "
            >{{ localization("Save") }}</el-button
          >
        </div>
      </el-dialog>
      <!-- // End For Restored Groups Dialog  -->

      <!-- // Start Add Another Members To Group  -->
      <el-dialog
        :title="`${localization('Add Additional Members')}`"
        :visible.sync="additional_members_gorup"
        width="50%"
        top="4vh"
        :class="{ 'text-right add_group_with_rtl': isRtl() }"
      >
        <el-form :rules="rules" ref="contactRef">
          <el-form-item :label="`${localization('Members')}`">
            <el-select
              ref="users"
              :placeholder="`${localization('Select Members')}`"
              style="width: 100%"
              v-model="selected_additionam_group_members"
              filterable
              multiple
              remote
              reserve-keyword
              :remote-method="search"
              :loading="loadingValue"
            >
              <el-option
                v-for="user in contactList"
                :key="user.id"
                :label="user.first_name + ' ' + user.last_name"
                :value="user.id"
              >
                <span style="color: #8492a6; font-size: 13px">
                  <i class="el-icon-user-solid"></i>
                  {{ user.first_name + " " + user.last_name }}
                </span>
              </el-option>
            </el-select>
            <div>Or invite members by email</div>
            <el-form-item
              label="Email"
              style="width: 100%"
              prop="questions_options"
            >
              <el-select
                v-model="emails"
                no-data-text="Type Email"
                multiple
                filterable
                allow-create
                default-first-option
                class=""
                style="width: 100%"
                placeholder="Type Email"
              >
              </el-select>
            </el-form-item>
          </el-form-item>
          <el-form-item label="Institution Users" prop="">
            <el-select
              v-model="selected_institutions_users"
              :placeholder="`${localization('Please select user')}`"
              style="width: 100%"
              multiple
              filterable
            >
              <el-option
                v-for="item in institutionUsersList"
                :key="item.id"
                :label="item.first_name + ' ' + item.last_name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <!-- //? Start Modal Footer Save && Cancel  -->
        <div
          slot="footer"
          class="
            dialog-footer
            dialog_footer
          "
        >
          <el-button
            @click="closeAdditionalMembersDialog"
            class="text-center d-block"
            >{{ localization("Cancel") }}</el-button
          >

          <el-button
            @click="addAdditionalMembersToGroup"
            class="
              text-center
              d-block
              text-capitalize
            "
            >{{ localization("Save") }}</el-button
          >
        </div>
      </el-dialog>
      <!--// End Add Another Members To Group  -->
    </div>
    <div v-else>
      <h2 class="text-dark text-center">
        {{
          localization("update your plan") != false
            ? localization("update your plan")
            : "Kindly update your plan to access this page"
        }}
      </h2>
    </div>
  </div>
</template>

<script>
import { Card, Table as LTable } from "src/components/index";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import ServerTable from "../../components/DataTablePagination.vue";
import "element-ui/lib/theme-chalk/display.css";
export default {
  name: "index",
  components: {
    Card,
    LTable,
    ServerTable
  },

  data() {
    return {
      group: {},
      groups: [],
      group_dialog: false,
      type: null,
      rules: {
        name: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "change"
          }
        ],
        email: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        groups_id: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "change"
          }
        ]
      },
      add_rules: {
        name: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ]
      },
      // new code
      search_group_dialog: false,
      groupName: "",
      selected_group_list: [],
      selected_institutions_users: [],
      newGroupMember: {},
      search_group: [],
      additional_members_gorup: false,
      selected_additionam_group_members: [],
      emails: [],
      getGroupMembersId: null,
      testPrintData: {},
      showTestPrintData: false
    };
  },
  mounted() {
    this.Refresh();
    // institutionUsersGroup
    this.$store.dispatch("groups/institutionUsersGroup");
  },
  computed: {
    getSelectedIds() {
      return this.selected_group_list;
    },
    ...mapGetters({
      loadingValue: ["groups/usersLoad"],
      groupMembers: ["groups/groupMembers"],
      searchContactsItems: ["contacts/searchContacts"],
      contactList: ["contacts/contactList"],
      institutionUsersList: ["groups/getInstUsers"]
    })
  },
  methods: {
    getGroupItem(item) {
      console.log(item);
      this.testPrintData = item;
      this.showTestPrintData = true;
    },
    Refresh() {
      this.group_dialog = false;
      this.search_group_dialog = false;
      this.getGroupsMembersList();
      this.$store.dispatch("groups/GetUserGroups").then(_ => {
        this.groups = this.$store.getters["groups/userGroups"];
      });

      this.selected_group_list = [];
      this.groupName = "";
      this.$store.dispatch("groups/getGroupMembers");
      // for test
      this.$store.dispatch("contacts/getSearchContacts");
    },
    getGroupsMembersList() {
      this.$store.dispatch("groups/getGroupMembers");
    },
    open_group_dialog(type, item) {
      this.group = item;
      this.type = type;
      this.group_dialog = true;
    },
    save() {
      this.$refs["addGroup"].validate(valid => {
        if (valid) {
          if (this.type == 1) {
            this.$store
              .dispatch("groups/AddGroup", { query: this.group })
              .then(() => {
                this.Refresh();
                this.group_dialog = false;
              });
          } else {
            this.$store
              .dispatch("groups/UpdateGroup", {
                id: this.group.id,
                query: this.group
              })
              .then(_ => {
                this.Refresh();
                this.group_dialog = false;
              });
          }
        }
      });
    },

    saveNewGroupMembers() {
      this.$refs["addGroup"].validate(valid => {
        if (valid) {
          if (this.type == 1) {
            this.$store
              .dispatch("groups/addNewGroupMembers", {
                name: this.newGroupMember.name,
                users: this.getSelectedIds
              })
              .then(_ => {
                this.selected_group_list = [];
                // this.groupName = "";
                Swal.fire({
                  text: `${this.localization("Done")}`,
                  confirmButtonText: `${this.localization("Ok")}`,
                  type: "success",
                  icon: "success",
                  confirmButtonClass: "btn btn-success",
                  buttonsStyling: false
                });
                this.newGroupMember.name = "";
                this.search_group_dialog = false;
                this.Refresh();
              });
          } else {
            this.$store
              .dispatch("groups/editeGroupName", {
                query: {
                  name: this.newGroupMember.name
                },
                id: this.newGroupMember.id
              })
              .then(_ => {
                // this.groupName = "";
                Swal.fire({
                  text: `${this.localization("Done")}`,
                  confirmButtonText: `${this.localization("Ok")}`,
                  type: "success",
                  icon: "success",
                  confirmButtonClass: "btn btn-success",
                  buttonsStyling: false
                });
                this.newGroupMember.name = "";
                this.search_group_dialog = false;
                this.Refresh();
              });
          }
        }
      });
    },

    remove(id) {
      Swal.fire({
        text: `${this.localization("Are You Sure")}`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success",
        cancelButtonClass: "btn btn-danger",
        confirmButtonText: `${this.localization("Delete")}`,
        cancelButtonText: `${this.localization("Cancel")}`,
        buttonsStyling: false,
        icon: "error"
      }).then(date => {
        if (date.isConfirmed) {
          this.$store.dispatch("groups/delete", id).then(_ => {
            this.Refresh();
            Swal.fire({
              title: "",
              text: `${this.localization("Deleted")}`,
              confirmButtonText: `${this.localization("Ok")}`,
              type: "success",
              confirmButtonClass: "btn btn-success",
              buttonsStyling: false,
              icon: "success"
            });
          });
        }
      });
    },
    search(query) {
      this.usersRoles(query);
    },

    usersRoles(search = "", limit = 10) {
      this.$store
        // .dispatch("contacts/getSearchContacts", {
        .dispatch("contacts/GetContactsList", {
          search_text: search
        })
        .then(() => {
          this.search_group = this.$store.getters["contacts/contactList"];
          // this.search_group = this.$store.getters["contacts/searchContacts"];
        });
    },
    openSearchGroupDialog(type, item) {
      this.newGroupMember = item;
      this.type = type;
      this.search_group_dialog = !this.search_group_dialog;
      this.search_group = this.newGroupMember.members;
      // this.selected_group_list = [1, 2];
      this.selected_group_list = [...this.newGroupMember.members].map(item => {
        return item.first_name + " " + item.last_name;
      });
    },

    // additional group members
    openAdditionalMembersDialog(id) {
      this.additional_members_gorup = !this.additional_members_gorup;
      this.selected_additionam_group_members = [];
      this.getGroupMembersId = id;
      // console.log(id);
    },
    closeAdditionalMembersDialog() {
      this.additional_members_gorup = !this.additional_members_gorup;
      this.selected_additionam_group_members = [];
      this.getGroupMembersId = null;
    },
    addAdditionalMembersToGroup() {
      this.$store
        .dispatch("groups/addMembersToGroup", {
          query: {
            users: this.selected_additionam_group_members,
            emails: this.emails,
            institution_users: this.selected_institutions_users
          },
          id: this.getGroupMembersId
        })
        .then(() => {
          this.selected_additionam_group_members = [];
          this.emails = [];
          this.selected_institutions_users = [];
          this.getGroupMembersId = null;
          this.additional_members_gorup = !this.additional_members_gorup;
          this.Refresh();
          Swal.fire({
            text: `${this.localization("Done")}`,
            confirmButtonText: `${this.localization("Ok")}`,
            type: "success",
            icon: "success",
            confirmButtonClass: "btn btn-success",
            buttonsStyling: false
          });
        });

      // selected_additionam_group_members: [],
      //   getGroupMembersId: null
    },
    removeMemberFromGroup(groupId, memberId) {
      Swal.fire({
        title: "",
        text: `${this.localization("Are You Sure")}`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success",
        cancelButtonClass: "btn btn-danger",
        confirmButtonText: `${this.localization("Delete")}`,
        cancelButtonText: `${this.localization("Cancel")}`,
        buttonsStyling: false,
        icon: "error"
      }).then(date => {
        if (date.isConfirmed) {
          this.$store
            .dispatch("groups/deleteMemberFromGroup", {
              group_id: groupId,
              member_id: memberId
            })
            .then(_ => {
              this.Refresh();
              Swal.fire({
                text: `${this.localization("Deleted")}`,
                confirmButtonText: `${this.localization("Ok")}`,
                type: "success",
                confirmButtonClass: "btn btn-success",
                icon: "success",
                buttonsStyling: false
              });
            });
        }
      });
    },
    export_groups(id, group_name) {
      this.$store
        .dispatch("groups/export_every_group", {
          id: id,
          group_name: group_name
        })
        .then(() => {
          Swal.fire({
            text: `${this.localization("Done")}`,
            confirmButtonText: `${this.localization("Ok")}`,
            type: "success",
            icon: "success",
            confirmButtonClass: "btn btn-success",
            buttonsStyling: false
          });
        });
    },
    printGroups() {
      window.print();
    }
  }
  // UpdateNewGroupMembers deleteMemberFromGroup

  // user --- add_group -- for add new group
  // user --- add_group_member -- just members
  //  remove_group_member/{group_id}/user_id
  // change_conatct_status [1-aproved, 2-pending, 0-remove] user_id, status
};
</script>

<style scoped lang="scss">
$transition: 0.3s all ease-in-out;
$textcolor: #f7f7f8;
$edit-color: #ffa534;
$add-color: #11bfe3;
$cancel-color: #fb404b;
$black: #000;
$white: #fff;
$box-shadow: 0 1px 12px rgba($black, 0.4);
$text-shadow: 2px 4px 3px rgba($black, 0.3);
$simple-shadow: 0 0 6px 0 rgba($black, 0.2), 0 0 5px -2px rgba($black, 0.1);

.is_wrap_just_print {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
  .btn_just_print {
    background-color: #3c3;
    color: $white;
    min-width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 5px;
    padding: 10px 8px;
  }
}

::v-deep .el-collapse-item {
  .el-collapse-item__header {
    padding: 40px 0;
    transition: $transition;
    @media (min-width: 320px) and (max-width: 450px) {
      padding: 50px 0;
    }
    &:hover,
    &.is-active {
      background-color: $textcolor !important;
    }
    // el-collapse-item__header
  }
}

//   Start server table style in all screens
.server_table_for_big_screens {
  display: block;
  @media (min-width: 320px) and (max-width: 997px) {
    display: none;
  }
  // for print table
  // @media print {
  //   display: block;
  // }
}

// @media print {
//   // * {
//   //   display: none;
//   // }
//   .server_table_for_big_screens {
//     display: block;
//   }
// }
.server_table_for_mobile_screen {
  display: none;
  @media (min-width: 320px) and (max-width: 997px) {
    display: block;
  }
}

::v-deep .el-table__row {
  .el-table_1_column_1 {
    padding: 0 !important;
    .cell {
      padding: 0 !important;
      // text-align: center;
    }
    // el-collapse-item__header
  }
}
// ::v-deep .el-table_6_column_20 {
//   @media (min-width: 320px) and (max-width: 767px) {
//     display: none !important;
//     .cell {
//       display: none !important;
//     }
//   }
// }
// *  End server table style in all screens

.actions-icons-container {
  gap: 8px;
  // padding: 5px 0;
  // display: flex;
  .actions-icons {
    font-size: 14px;
    width: 30px;
    height: 30px;
    box-shadow: $simple-shadow;
    text-align: center;
    line-height: 30px;
    border-radius: 50%;
    cursor: pointer;
    color: #777;
    transition: $transition;
    &.for-delete-member {
      @media (min-width: 320px) and (max-width: 991px) {
        position: absolute;
        right: 5px;
        top: 5px;
      }
    }
    &.members-group-numbers {
      background-color: $add-color;
      color: $white;
    }
    &.add {
      // margin-bottom: 0.3rem;
      &:hover {
        background-color: $add-color;
        color: $white;
      }
    }
    &.edit {
      &:hover {
        background-color: $edit-color;
        color: $white;
      }
    }
    &.delete {
      &:hover {
        background-color: $cancel-color;
        color: $white;
      }
    }
    &.export {
      &:hover {
        background-color: #3c3;
        color: $white;
      }
    }
  }
}
.main-accordion-header {
  width: 95%;
  @media (min-width: 320px) and (max-width: 450px) {
    display: block !important;
  }
  .member-name {
    margin-bottom: 0;
    @media (min-width: 320px) and (max-width: 450px) {
      margin-bottom: 1rem;
    }
  }

  .btn-info {
    color: $add-color;
  }
  .btn-danger {
    color: $cancel-color;
  }
}
.main-members-container {
  padding: 0.5rem 0.3rem;
  margin-top: 0.7rem;
  position: relative;
  &.for-small {
    padding-bottom: 1rem;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    display: block !important;
  }
  .user-details {
    width: 300px;
    @media (min-width: 767px) and (max-width: 991px) {
      width: 200px;
    }
    @media (min-width: 320px) and (max-width: 767px) {
      width: 100%;
    }
    &.for-small {
      width: auto;
      gap: 50px;
    }
    .part-one {
      color: #777;
      gap: 8px;
      // align-items: center;
      .user-icon {
        font-size: 14px;
        line-height: 2;
      }
      .user-name {
        word-break: break-all;
        transition: $transition;
        color: #707070 !important;
      }
      .user-email {
        word-break: break-all;
      }
    }
  }
}
.main-scores-container {
  // justify-content: flex-start !important;

  .every-score {
    gap: 7px;
    margin-bottom: 3px;
    @media (min-width: 320px) and (max-width: 480px) {
      margin-bottom: 10px !important;
      gap: 10px;
      min-width: 50%;
    }
    .score-word {
      color: #777;
    }
  }
  &.main-scores-container-sm {
    @media (min-width: 320px) and (max-width: 767px) {
      margin-top: 15px !important;
    }
  }
}

::v-deep .precision {
  background-color: #3c3;
}

::v-deep .technical_reasoning {
  background-color: #f93;
}

::v-deep .sequence {
  background-color: #39c;
}

::v-deep .confluence {
  background-color: #c36;
}
::v-deep .el-table .cell {
  padding: 4px;
  @media (min-width: 320px) and (max-width: 767px) {
    padding-right: 0;
  }
}
::v-deep .el-table tbody tr td:first-child {
  padding-left: 0;
  @media (min-width: 320px) and (max-width: 767px) {
    padding-left: 0;
  }
}
::v-deep .el-table thead tr th:first-child {
  padding-left: 0;
}
.label {
  padding: 0.5rem;
  border-radius: 50%;
  // margin: 0 0.5rem;
  width: 40px;
  height: 40px;
  text-align: center;
  color: #fff;
  @media print {
    background-color: #3c3;
  }
}
// ? dialog style
::v-deep .el-dialog {
  @media (min-width: 320px) and (max-width: 480px) {
    width: 90% !important;
  }
  @media (min-width: 480px) and (max-width: 767px) {
    width: 70% !important;
  }
}

.dialog_footer {
  display: flex;
  justify-content: end;
  align-items: center;
  @media (min-width: 320px) and (max-width: 991px) {
    justify-content: center;
  }
}

.header_contact_wrapping {
  @media (min-width: 320px) and (max-width: 480px) {
    display: block !important;
  }
  .add_contact_buttons {
    @media (min-width: 320px) and (max-width: 480px) {
      margin: 15px 0 10px;
    }
  }
}
.contact_title {
  &.is_rtl {
    display: flex;
    gap: 8px;
    flex-direction: row-reverse;
    align-items: center;
  }
}
</style>

<style lang="scss">
.add_group_with_rtl {
  .el-form-item__label {
    width: 100%;
  }
  .el-input__inner {
    text-align: right;
  }
}

.just_for_print_group {
  @media print {
    // patterns
    .precision {
      background-color: #3c3;
    }

    .technical_reasoning {
      background-color: #f93;
    }
    .sequence {
      background-color: #39c;
    }

    .confluence {
      background-color: #c36;
    }
    .server_table_for_big_screens {
      display: block !important;
    }
    .server_table_for_mobile_screen {
      display: none !important;
    }
  }
}
</style>
