import Vue from "vue";
import Router from "vue-router";
import { isLogged, notHasPermissions } from "../utils/auth";
// import { ROUTER_DEFAULT_CONFIG } from "Config/index";
// import { routerBeforeEachFunc } from "Config/interceptors/router";

Vue.use(Router);

import DashboardLayout from "src/pages/Dashboard/Layout/DashboardLayout.vue";
// GeneralViews
import NotFound from "src/pages/GeneralViews/NotFoundPage.vue";
// Dashboard pages
import Overview from "src/pages/Dashboard/Dashboard/Overview.vue";
// import Stats from 'src/pages/Dashboard/Dashboard/Stats.vue'

import Login from "src/pages/Dashboard/Pages/Login2.vue";
// import Register from 'src/pages/Dashboard/Pages/Register.vue'
import Register from "src/pages/Dashboard/Pages/Register2.vue";
import RegisterFamily from "src/pages/Dashboard/Pages/RegisterFamily.vue";
import RegisterAnnual from "src/pages/Dashboard/Pages/RegisterAnnual.vue";
import RegisterCoaching from "src/pages/Dashboard/Pages/RegisterCoaching.vue";
import ResetPassword from "src/pages/Dashboard/Pages/ResetPassword.vue";
import Lock from "src/pages/Dashboard/Pages/Lock.vue";
import Logout from "src/views/pages/logout";

import VueRouter from "vue-router";

let loginPage = {
  path: "/login",
  name: "Login",
  component: Login
};

let invitation = {
  name: "invitation",
  path: "/invitation/:id/:title",
  component: () => import("@/views/contacts/invitation.vue")
};
let registerPage = {
  path: "/register-basic/:contact?/:team?/:token?",
  name: "Register",
  component: Register
};
let registerFamilyPage = {
  path: "/register-family/:contact?/:team?/:token?",
  name: "Register Family",
  component: RegisterFamily
};
let registerAnnualPage = {
  path: "/register-professional/:contact?/:team?/:token?",
  name: "Register Annual",
  component: RegisterAnnual
};
let registerCoachingPage = {
  path: "/register-coaching/:contact?/:team?/:token?",
  name: "Register Annual",
  component: RegisterCoaching
};

let resetPasswordPage = {
  path: "/reset-password/:token",
  name: "ResetPassword",
  component: ResetPassword
};

let lockPage = {
  path: "/lock",
  name: "Lock",
  component: Lock
};

const router = new VueRouter({
  routes: configRoutes(), // short for routes: routes
  mode: "history",
  linkActiveClass: "active"
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    !isLogged()
      ? next({ path: "/login", params: { nextUrl: to.fullPath } })
      : next();
  } else {
    next();
  }
});

router.afterEach((to, from) => {
  // scroll to top when rout change
  window.scrollTo(0, 0);
});

function configRoutes() {
  return [
    {
      path: "/",
      component: DashboardLayout,
      redirect: "/overview",
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: "/overview",
          name: "Home",
          component: Overview
        },
        {
          path: "/logout",
          name: "logout",
          component: Logout
        },
        {
          name: "Personal Learning Profile",
          path: "/profile/",
          component: () => import("src/views/users/show")
        },
        {
          // Edit user data
          name: "Edit Profile",
          path: "edit-user/:id?",
          meta: true,
          component: () => import("src/views/users/edit")
        },
        {
          // Edit user data
          name: "Edit Profile",
          path: "/sync-data",
          meta: true,
          component: () => import("src/views/users/edit")
        },
        {
          // view user data from users table
          name: "User Profile",
          path: "/user-profile/:id",

          component: () => import("src/views/users/ViewUserProfile")
        },
        // Print User Tasks
        {
          // view user data from users table
          name: "User Tasks",
          path: "/user-profile/:id/user-tasks",
          component: () => import("src/views/users/PrintUserTasks")
        },
        {
          // view user data from users table
          name: "User Profile",
          path: "/user-details/:id",
          meta: true,
          component: () => import("src/views/users/showUserDetails.vue")
        },
        {
          name: "Use Your Patterns",
          // name: "Show Task",
          path: "edit-task/:id?",
          meta: true,
          component: () => import("src/views/tasks/edit")
        },
        {
          name: "Attributes",
          path: "attributes",
          component: () => import("src/views/attributes/index")
        },
        {
          name: "Team Statements",
          path: "team-statements",
          component: () => import("src/views/team-statements/index")
        },
        {
          name: "Statements Questions",
          path: "statements-questions",
          component: () => import("src/views/statements-questions/index")
        },
        {
          name: "Words",
          path: "words",
          component: () => import("src/views/words/index")
        },
        //// Localization
        {
          name: "Localization",
          path: "localization",
          component: () => import("src/views/localization/index")
        },
        {
          name: "User Categories",
          path: "user-categories",
          component: () => import("src/views/user-categories/index")
        },
        {
          name: "User Categories Details",
          path: "user-categories/:id",
          component: () => import("src/views/user-categories/show")
        },
        {
          name: "Edit Localization",
          path: "localization/:id",
          meta: true,
          component: () => import("src/views/localization/show")
        },
        {
          name: "Use Your Patterns",
          path: "tasks/:id?",
          component: () => import("src/views/tasks/index")
        },
        // Use Your Patterns -- At Work Page
        {
          name: "At Work",
          path: "at-work/:id?",
          component: () => import("src/views/tasks-at-work/index")
        },
        {
          name: "At Work Details",
          path: "edit-at-work-task/:id?",
          meta: true,
          component: () => import("src/views/tasks-at-work/edit")
        },
        // Use Your Patterns -- Career Match Page
        {
          name: "Career Match",
          path: "career-match/:id?",
          component: () => import("src/views/tasks-career-match/index")
        },
        {
          name: "Career Match Details",
          path: "edit-career-match-task/:id?",
          meta: true,
          component: () => import("src/views/tasks-career-match/edit")
        },
        {
          name: "Family Members",
          path: "family-members",
          component: () => import("src/views/family-members/index")
        },
        {
          name: "Families",
          path: "families",
          component: () => import("src/views/families/index")
        },
        {
          name: "Family Details",
          path: "families/:id",
          component: () => import("src/views/families/show")
        },
        {
          name: "Family Member Profile",
          path: "families/family-profile/:id",
          component: () => import("src/views/families/family-profile")
        },
        // start career-statements
        {
          name: "Career Statements",
          path: "career-statements",
          component: () => import("src/views/career-statements/index")
        },
        {
          name: "Career Statements Questions",
          path: "career-statements-questions",
          component: () => import("src/views/career-statements-questions/index")
        },
        {
          name: "Career Match Questions",
          path: "career-match-questions",
          component: () => import("src/views/career-questions/index")
        },
        {
          name: "Career Strategy Details",
          path: "career-strategies/:id",
          component: () => import("src/views/career-strategies/show")
        },
        {
          name: "Career Strategies",
          path: "career-strategies",
          component: () => import("src/views/career-strategies/index")
        },
        // end career-statements
        {
          name: "Questions",
          path: "questions",
          component: () => import("src/views/questionnaire/index")
        },
        {
          name: "Question",
          path: "questions/:id",
          meta: true,
          component: () => import("src/views/questionnaire/show")
        },
        {
          name: "LCI",
          path: "edit-questionnaire/",
          meta: true,
          component: () => import("src/views/questionnaire/questionnaire")
        },
        {
          name: "User LCI",
          path: "edit-questionnaire/:id",
          meta: true,
          component: () => import("src/views/questionnaire/questionnaire")
        },
        {
          name: "LCI",
          path: "before",
          component: () => import("src/views/questionnaire/before")
        },

        {
          name: "Strategies",
          path: "strategies",
          component: () => import("src/views/strategies/index")
        },
        {
          name: "Strategy",
          path: "Strategy/:id",
          meta: true,
          component: () => import("src/views/strategies/show")
        },
        {
          name: "Topics",
          path: "topics",
          component: () => import("src/views/topics/index")
        },
        {
          name: "Topic",
          path: "topic/:id",
          meta: true,
          component: () => import("src/views/topics/show")
        },
        {
          name: "versions",
          path: "topic/:id/:version_id",
          meta: true,
          component: () => import("src/views/topics/show-versions")
        },
        {
          name: "Tasks",
          path: "topics/:id/topics-tasks",
          meta: true,
          component: () => import("src/views/topics/topics-tasks")
        },

        {
          name: "Users",
          path: "users",
          component: () => import("src/views/users/index")
        },
        {
          name: "Institutions",
          path: "institution",
          component: () => import("src/views/institution/index")
        },
        {
          name: "Institution",
          path: "show-institution/:id",
          meta: true,
          component: () => import("src/views/institution/show")
        },
        {
          name: "User Profile",
          path: "show-institution/user-profile/:id",
          meta: true,

          component: () => import("src/views/users/ViewUserProfile")
        },
        {
          name: "Logs",
          path: "logs",
          component: () => import("src/views/logs/index")
        },

        {
          name: "Roles",
          path: "roles",
          component: () => import("src/views/permission/index")
        },
        {
          name: "User Permissions",
          path: "permissions/:id",
          meta: true,
          component: () => import("src/views/permission/show")
        },
        {
          name: "Management",
          path: "management",
          component: () => import("src/views/managment/index")
        },
        {
          name: "Languages",
          path: "languages",
          component: () => import("src/views/language/index")
        },
        {
          name: "Edit Content",
          path: "page/:id",
          meta: true,
          component: () => import("src/views/cms/edit")
        },
        {
          name: "Contacts",
          path: "contacts",
          component: () => import("src/views/contacts/index")
        },

        {
          name: "Groups",
          path: "groups",
          component: () => import("src/views/groups/index")
        },
        {
          name: "Website CMS",
          path: "cms",
          component: () => import("src/views/cms/index")
        },
        {
          name: "User Dashboard",
          path: "user-dashboard",
          component: () => import("src/views/user-dashboard/UserDashboard")
        },
        {
          name: "Teams",
          path: "teams",
          component: () => import("src/views/my-teams/MyTeams")
        },
        {
          name: "Team Details",
          path: "teams/:id",
          component: () => import("src/views/my-teams/TeamDetails")
        },
        {
          name: "Introduce Yourself",
          path: "introduce-yourself",
          component: () => import("src/views/introducing-yourself/index")
        },
        {
          name: "Building The Team",
          path: "building-team",
          component: () => import("src/views/building-team/index")
        },
        {
          name: "Working Together",
          path: "working-together",
          component: () => import("src/views/working-together/index")
        },
        {
          name: "Tackling Project",
          path: "tackling-project",
          component: () => import("src/views/talking-project/index")
        },
        {
          name: "Tackling Project",
          path: "tackling-project/:id",
          component: () => import("src/views/talking-project/show-team-task")
        },
        {
          name: "Task Categories",
          path: "task-categories",
          component: () => import("src/views/task-category/index")
        },
        {
          name: "Category Tasks",
          path: "task-categories/:id",
          component: () => import("src/views/task-category/show")
        },
        {
          name: "How Will the LCI Help You?",
          path: "lci-help-you",
          component: () => import("src/views/lci-help-you/index")
        },
        {
          name: "Videos",
          path: "videos",
          component: () => import("src/views/videos/index")
        },
        {
          name: "My Dashboard",
          path: "my-dashboard",
          component: () => import("src/views/my-dashboard/index")
        },
        // New Theme
        {
          name: "InstructionsView",
          path: "new-theme/instructions",
          component: () =>
            import("src/views/a-new-theme/lci-pages/InstructionsView.vue"),
          meta: { hideSidebar: true, hideNavbar: true }
        },
        {
          name: "LciQuestions",
          path: "new-theme/questions",
          component: () =>
            import("src/views/a-new-theme/lci-pages/LciQuestions.vue"),
          meta: { hideSidebar: true, hideNavbar: true }
        },
        {
          name: "ProfileView",
          path: "new-theme/profile/:id?",
          component: () =>
            import("src/views/a-new-theme/profile/ProfileView.vue")
        },
        {
          name: "Personalize Your Profile",
          path: "new-theme/personalize-profile/:id?",
          component: () =>
            import("src/views/a-new-theme/profile/PersonalizeProfile.vue")
        },
        {
          name: "Personalize Your Profile plp",
          path: "new-theme/plp/:id?",
          component: () => import("src/views/a-new-theme/plp/PlpView.vue")
        },
        {
          name: "ContactView",
          path: "new-theme/contacts",
          component: () =>
            import("src/views/a-new-theme/contacts/ContactView.vue")
        },
        {
          name: "TasksView",
          path: "new-theme/tasks",
          component: () => import("src/views/a-new-theme/tasks/TasksView.vue")
        },
        {
          name: "TaskDetails",
          path: "new-theme/tasks/:task_id",
          component: () => import("src/views/a-new-theme/tasks/TaskDetails.vue")
        },
        {
          name: "WorkView",
          path: "new-theme/at-work",
          component: () =>
            import("src/views/a-new-theme/tasks/at-work/WorkView.vue")
        },
        {
          name: "WorkDetails",
          path: "new-theme/at-work/:task_id",
          component: () =>
            import("src/views/a-new-theme/tasks/at-work/WorkDetails.vue")
        },
        {
          name: "CareerView",
          path: "new-theme/career",
          component: () =>
            import("src/views/a-new-theme/tasks/career/CareerView.vue")
        },
        {
          name: "TeamsView",
          path: "new-theme/teams",
          component: () =>
            import("src/views/a-new-theme/teams/Teams.vue")
        },
        {
          name: "TeamDetails",
          path: "new-theme/teams/:id",
          component: () =>
            import("src/views/a-new-theme/teams/TeamDetails.vue")
        },
        {
          name: "GroupsList",
          path: "new-theme/groups",
          component: () =>
            import("src/views/a-new-theme/groups/Groups.vue")
        },
        {
          name: "GroupDetails",
          path: "new-theme/groups/:id",
          component: () =>
            import("src/views/a-new-theme/groups/GroupDetails.vue")
        },
        {
          name: "BuildTeam",
          path: "new-theme/build-team",
          component: () =>
            import("src/views/a-new-theme/teams/BuildTeam.vue")
        },
        {
          name: "step-1",
          path: "new-theme/build-team/step-1",
          component: () =>
            import("src/views/a-new-theme/teams/step-1.vue")
        },
        {
          name: "step-2",
          path: "new-theme/build-team/step-2",
          component: () =>
            import("src/views/a-new-theme/teams/step-2.vue")
        },
        {
          name: "step-3",
          path: "new-theme/build-team/step-3",
          component: () =>
            import("src/views/a-new-theme/teams/step-3.vue")
        },
        {
          name: "step-4",
          path: "new-theme/build-team/step-4/:id",
          component: () =>
            import("src/views/a-new-theme/teams/step-4.vue")
        },
        {
          name: "CareerDetails",
          path: "new-theme/career/:task_id",
          component: () =>
            import("src/views/a-new-theme/tasks/career/CareerDetails.vue")
        },
        {
          name: "Personal Work Profile (PWP)",
          path: "new-theme/pwp",
          component: () =>
            import("src/views/a-new-theme/tasks/pwp/PwpCareer.vue"),
          meta: { hideNavbar: true }
        },
        {
          name: "PwpQuestions",
          path: "new-theme/pwp-questions",
          component: () =>
            import("src/views/a-new-theme/tasks/pwp/PwpQuestions.vue"),
          meta: { hideSidebar: true, hideNavbar: true }
        }
      ]
    },
    loginPage,
    invitation,
    registerPage,
    resetPasswordPage,
    registerFamilyPage,
    registerAnnualPage,
    registerCoachingPage,
    // lockPage,
    { path: "*", component: NotFound }
  ];
}

export default router;
