<template>
  <div class="col working_together_wrapper">
    <card>
      <!-- Tasks List  -->
      <!-- //// start Large Screens  -->
      <CustomTable
        :DataItems="allTeamTasks"
        @Refresh="Refresh"
        @reset="filter = {}"
        :title="'Tasks'"
        hidden_export_import
        admin_permission="admin_tasks"
        edit_permission="add_tasks"
        :loading="loadingValue"
        @toggleModal="ToggleTaskModal('add')"
        viewName="Tasks"
      >
        <template #columns>
          <!-- Task Name  -->
          <el-table-column
            prop="name"
            sortable
            :label="`${localization('Name')}`"
          ></el-table-column>
          <el-table-column prop label="_">
            <template slot-scope="scope">
              <div class="td_actions flex justify-content-end">
                <router-link
                  :to="`/tackling-project/${scope.row.id}`"
                  v-tooltip.top-center="
                    `${localization('Show personal strategy')}`
                  "
                  class="btn btn-info btn-link btn-xs"
                >
                  <i class="fa fa-eye"></i>
                </router-link>
                <a
                  @click="remove(scope.row.id)"
                  v-tooltip.top-center="
                    `${localization('Delete personal strategy')}`
                  "
                  class="btn btn-danger btn-simple btn-link"
                >
                  <i class="fa fa-times"></i>
                </a>
              </div>
            </template>
          </el-table-column>
        </template>
        <template #action>
          <button
            @click="add_team_task = true"
            :disabled="team_id == null"
            class="cu_btn_add"
          >
            <el-icon class="el-icon-plus"></el-icon>
            {{ localization("Add Task") }}
          </button>
        </template>
      </CustomTable>

      <el-dialog
        title="Share personal strategy"
        :visible.sync="share_personal_dialog"
        width="50%"
        top="4vh"
      >
        <el-select
          v-model="team_id"
          placeholder="Please select team"
          style="width: 100%"
        >
          <el-option
            v-for="team in allTeamsList"
            :key="team.id"
            :label="team.name"
            :value="team.id"
          ></el-option>
        </el-select>
        <div slot="footer" class="dialog-footer dialog_footer">
          <el-button @click="openShareDialog" class="text-center d-block">
            {{ localization("Cancel") }}
          </el-button>
          <el-button
            type="submit"
            @click="openShareDialog"
            class="text-center d-block text-capitalize"
            >Share</el-button
          >
        </div>
      </el-dialog>

      <!-- Start Add Task  -->
      <el-dialog
        :title="`${localization('Add Task')}`"
        :visible.sync="add_team_task"
        width="50%"
        top="4vh"
      >
        <el-form
          :model="teamTaskData"
          :rules="rules"
          ref="taskRef"
          @submit.prevent="handlerAddTeamTasks"
        >
          <el-form-item :label="`${localization('Name')}`" prop="name">
            <el-input
              :placeholder="`${localization('Name')}`"
              v-model="teamTaskData.name"
            ></el-input>
          </el-form-item>
          <el-form-item
            :label="`${localization('Description')}`"
            prop="description"
          >
            <el-input
              :placeholder="`${localization('Description')}`"
              v-model="teamTaskData.description"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer dialog_footer">
          <el-button
            @click="add_team_task = false"
            class="text-center d-block"
            >{{ localization("Cancel") }}</el-button
          >

          <el-button
            @click="handlerAddTeamTasks"
            class="text-center d-block text-capitalize"
            >{{ localization("Save") }}</el-button
          >
        </div>
      </el-dialog>
      <!-- End Add Task  -->
      <!-- <div
        class="team_routes_wrapper"
        :class="{ 'd-flex flex-row-reverse': isRtl() }"
      >
        <router-link class="back_link" to="/teams">
          <i class="fa fa-arrow-left back_icon mr-1"></i>
          {{ localization("Back to team") }}
        </router-link>
      </div> -->
    </card>
  </div>
</template>


<script>
import { Card, Table as LTable } from "src/components/index";
import { mapGetters } from "vuex";
import "element-ui/lib/theme-chalk/display.css";
import Swal from "sweetalert2";

export default {
  components: {
    Card,
    LTable
  },
  data() {
    return {
      filter: {},
      topic_id: null,
      share_personal_dialog: false,
      team_id: null,
      add_team_task: false,
      teamTaskData: {
        name: null,
        description: null
      },
      rules: {
        name: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        description: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ]
      }
    };
  },
  mounted() {
    this.$store.dispatch("user/get_all_teams_content");
    this.Refresh();
    this.$store.dispatch("user/get_all_teams");
    this.$store.dispatch("topics/GetTopics", { query: null });
    this.handlerTeamTasks();
  },
  computed: {
    ...mapGetters({
      loadingValue: ["user/usersLoad"],
      team_pages_content: ["user/get_team_pages_content"],
      team_pages_titles_content: ["user/get_team_pages_titles_content"],
      allTeamsList: ["user/get_teams"],
      allTeamTasks: ["user/getAllTeamTasks"]
    }),
    id() {
      return this.$route.params.id ? this.$route.params.id : this.auth().id;
    },
    tasks() {
      return this.$store.getters["user/tasks"];
    }
  },
  methods: {
    Refresh(query) {
      this.$store
        .dispatch("user/userTask", { id: this.id, query: query })
        .then(() => {
          this.$store.dispatch("topics/GetTopics", { query: null });
        });
    },
    remove(id) {
      Swal.fire({
        title: "",
        text: `${this.localization("Are You Sure")}`,
        type: "warning",
        icon: "error",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success ",
        cancelButtonClass: "btn btn-danger ",
        confirmButtonText: `${this.localization("Delete")}`,
        cancelButtonText: `${this.localization("Cancel")}`,
        buttonsStyling: false
      }).then(date => {
        if (date.isConfirmed) {
          this.$store
            .dispatch("user/deleteTeamTaskTalkingProject", id)
            .then(_ => {
              this.handlerTeamTasks();
              Swal.fire({
                title: "",
                icon: "success",
                type: "success",
                text: `${this.localization("Deleted")}`,
                confirmButtonText: `${this.localization("Ok")}`,
                confirmButtonClass: "btn btn-success ",
                buttonsStyling: false
              });
            });
        }
      });
    },
    openShareDialog() {
      this.share_personal_dialog = !this.share_personal_dialog;
    },
    handlerTeamTasks(query) {
      this.$store.dispatch("user/handlerTeamTasks", {
        id: this.team_id,
        query: query
      });
    },

    handlerAddTeamTasks() {
      this.$refs["taskRef"].validate(valid => {
        if (valid) {
          this.$store
            .dispatch("user/addNewTaskTalkingProject", {
              id: this.team_id,
              query: this.teamTaskData
            })
            .then(res => {
              // console.log(res.task.id);
              this.handlerTeamTasks();
              this.add_team_task = false;
              this.teamTaskData.name = null;
              this.teamTaskData.description = null;

              Swal.fire({
                title: "",
                icon: "success",
                text: `${this.localization("Done")}`,
                confirmButtonText: `${this.localization("Ok")}`,
                type: "success",
                confirmButtonClass: "btn btn-success ",
                buttonsStyling: false
              });
              this.$router.push(`/tackling-project/${res.task.id}`);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.main_custom_parts_pattern {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  align-items: center;
  .part_pattern {
    border: 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    border-radius: 16px;
    height: 25px;
    padding: 10px 13px;
    width: 55px;
    font-weight: 500;
    &.s {
      color: #39c;
      border-color: #39c;
    }
    &.p {
      color: #3c3;
      border-color: #3c3;
    }
    &.t {
      color: #f93;
      border-color: #f93;
    }
    &.c {
      color: #c36;
      border-color: #c36;
    }
  }
}
.text {
  color: #667085;
  font-weight: 400;
  font-size: 16px;
}
.main_profile_wrapper {
  background: #fff;
  width: 100%;
  max-width: 100%;
}
.main_title {
  color: #101828;
  font-weight: 600;
  font-size: 20px;
  &.white_text {
    color: #fff;
  }
}
.padding {
  padding: 14px;
}
.main_table {
  margin-top: 30px;
  border-radius: 8px;
  border: 1px solid #eaecf0;

  .head_table {
    border-bottom: 1px solid #eaecf0;
  }
}
</style>

<style scoped>
.task-type {
  margin-bottom: 20px;
}
.task-form {
  margin-bottom: 20px;
}
.file-upload {
  margin-bottom: 20px;
  text-align: center;
}

.el-upload {
  border: 2px dashed #d9d9d9;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
}

/* Custom Radio Buttons */
/* .custom-radio-group {
    display: flex;
    justify-content: space-between;
  }

  .custom-radio {
    display: inline-flex;
    align-items: center;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    transition: border-color 0.3s;
  }

  .custom-radio.is-checked {
    border-color: #67c23a;
    background-color: rgba(103, 194, 58, 0.1);
  } */
</style>
