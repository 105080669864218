<template>
  <div class="w-100 m-0 p-0">
    <div class=" mt-2 ">
      <div class="main_table">
        <div class="header_container just_custom_table_tasks">
          <CustomTable
            :count="team_members.length"
            :DataItems="team_members"
            @Refresh="refresh"
            @reset="filter = {}"
            :filter="filter"
            :title="'Tasks'"
            hidden_export_import
            admin_permission="admin_tasks"
            edit_permission="add_tasks"
            class="team_members"
            :isGroup="true"
            :hasFilter="false"
            :selection="false"
          >
            <template #columns>
              <!-- <el-table-column type="selection" width="40"></el-table-column> -->
              <el-table-column prop="first_name" width="200" :label="`${localization('Member Name')}`" :style="{ borderRight: '1px solid #ddd' }" >
                <template slot-scope="props">
                  <div class="d-flex ">
                    <h5 class="m-0">{{ props.row.first_name }} </h5>
                    <h5 class="m-0">{{ props.row.last_name }}</h5>
                  </div>
                  <div>
                    <span>{{ props.row.classification }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="pattern_statements" :label="`${localization('Statement')} (S)`" >
                <template slot-scope="props">
                  <span v-if="props.row.pattern_statements"> {{ props.row.pattern_statements.statement }} </span>
                </template>
              </el-table-column>
            </template>
          </CustomTable>
        </div>
        <div class="mt-4" >
          <div>
            <h5 class="m-0">
              How you will work with this team?
            </h5>
            <small class="text-muted">After reviewing what your team members bring and need to a team, write a statement of how you will work with THIS team.</small>
          </div>
          <el-form
              :rules="rules"
              ref="addTeam"
              :class="{ 'text-right build_with_rtl': isRtl() }"
          >
            <div class="row pt-2" :class="{ 'd-flex flex-row-reverse': isRtl() }">
                <div class="col-md-12 my-1">
                    <el-form-item :label="`${localization('Write your statment *')}`" prop="statment">
                        <el-input
                            :placeholder="`${localization('Give you team a name')}`"
                            v-model="statment"
                        ></el-input>
                    </el-form-item>
                </div>
            </div>
          </el-form>
        </div>
      </div>
      <div class="mt-3 d-flex justify-content-center mb-5">
        <!-- <button
          @click="prevTab"
          class="prev_next"
        >
          <img
            src="@/assets/new-theme/icons/arrow-narrow-left-active-Button.png"
            alt="edit icon"
          />
        </button> -->
        <button
          @click="save_statement()"
          class="lci_btn save mx-4"
        >
          Get started!
        </button>

        <!-- <button
          @click="nextTab"
          class="prev_next"
        >
          <img
            src="@/assets/new-theme/icons/arrow-narrow-right-Disable-Button.png"
            alt="edit icon"
          />
        </button> -->
      </div>
    </div>
  </div>
</template>
  
<script>
import { Card } from "src/components/index";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import { ChartCard, StatsCard } from "src/components/index";
export default {
  data() {
    return {
    
    };
  },
  components: {
    Card,
    ChartCard,
    StatsCard
  },
  computed: {
    ...mapGetters({
      loadinglabel: ["user/usersLoad"],
      team_details: ["user/get_team_details"],
      team_members: ["user/get_team_members"],
      invited_team_members: ["user/get_invited_team_members"]
    }),
    teamId() {
      return this.$route.params.id ? this.$route.params.id : null;
    },
  },
  data(){
    return {
      selected: [],
      filter:{},
      radio: 'sequence',
      statment: null,
      rules: {
        statment: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ]
      },
    }
  },
  mounted() {
    this.get_team_details();  
  },
  methods: {
    refresh(){
      this.$store.dispatch("user/every_team_members", this.teamId);
    },
    get_team_details() {
      this.$store.dispatch("user/every_team_members", this.teamId);
    },
    handleStatements(user_statements, pattern){
      if(user_statements){

        const user_statements_array = Object.values(user_statements)[0] 

        if(user_statements_array){
          const sequance = user_statements_array['Sequence']
          const confluence = user_statements_array['Confluence']
          const precision = user_statements_array['Precision']
          const technical_reasoning = user_statements_array['Technical Reasoning']
          if(pattern == 'Sequence'){
            return this.generateOlFromStatements(sequance)
          }else if(pattern == 'Confluence'){
            return this.generateOlFromStatements(confluence)
          }else if(pattern == 'Precision'){
            return this.generateOlFromStatements(precision)
          }else if(pattern == 'Technical Reasoning'){
            return this.generateOlFromStatements(technical_reasoning)
          }
        }
        
      }
    },
    generateOlFromStatements(patternArray) {
      // Flatten the object to get all statements
      const statements = Object.values(patternArray)
        .flat() // Combine all arrays into one
        .map(item => item.statement); // Extract only the statement property

      // Generate the <ol> tag with <li> for each statement
      const olTag = `<ol>${statements.map(statement => `<li>${statement}</li>`).join('')}</ol>`;
      
      return olTag;
    },
    save_statement() {
      // this.$refs["addTeam"].validate(valid => {
      //   if (valid) {
      //     this.$store
      //       .dispatch("user/add_team", {
      //         name: this.teamForm.name,
      //         emails: this.teamForm.emails,
      //         users: selectedMem
      //       })
      //       .then(response => {
      //         Swal.fire({
      //           title: "",
      //           icon: "success",
      //           text: `${this.localization("Done")}`,
      //           confirmButtonText: `${this.localization("Ok")}`,
      //           type: "success",
      //           confirmButtonClass: "btn btn-success ",
      //           buttonsStyling: false
      //         }).then(data => {
      //           if (data.isConfirmed) {
      //             this.teamForm.name = "";
      //             this.teamForm.emails = [];
      //             this.teamForm.selected_team_members = [];
      //             this.teamForm.selected_institutions_users = [];
      //             this.teamLineGraphHandlerForEmptyData();
      //             this.$emit("teamsAnswers", response.team.id)
      //           }
      //         });
      //       });
      //   } else {
      //     console.log("test");
      //   }
      // });
      this.$router.push( { name:'TeamDetails' , params:{ id: this.teamId } } )
    }
  }
};
</script>
<style scoped lang="scss">
::v-deep .el-tabs--border-card > .el-tabs__header {
  border-radius: 10px !important;
}
::v-deep .el-radio__input.is-checked+.el-radio__label{
  color:#000
}
.alert-custom {
  background-color: #fffaf0; /* Light background similar to your image */
  border: 1px solid #ffd580; /* Border color to match */
  color: #c35a00; /* Text color to match the orange */
  border-radius: 10px;
}

.alert-custom i {
  color: #c35a00; /* Icon color */
  font-size: 20px;
}

.alert-custom a {
  color: #c35a00;
  text-decoration: none;
}

.alert-custom a:hover {
  text-decoration: underline;
}

::v-deep .el-tabs--border-card, .el-tabs__content{
  border: unset !important;
}

::v-deep .el-tabs__nav-scroll{
  padding: 10px !important;
}

::v-deep .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active{
  border-top-color: #DCDFE6 !important;
  border-bottom-color: #DCDFE6 !important;
  border-radius: 7px !important;
}

::v-deep .team_members{
  .el-table .cell{
    overflow: unset !important;
  }
}

::v-deep.el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active{
  color: #344054;
}

::v-deep .el-tabs--border-card{
  box-shadow: unset !important;
}

::v-deep .el-tabs--border-card>.el-tabs__header{
  border-bottom: unset !important;
}

::v-deep  .new_theme_custom_table_pagination .card .card-body {
    padding: 0px 0px !important;
    border: unset !important;
}

::v-deep  .team_members .el-table th,
::v-deep  .team_members .el-table td {
  border-right: 1px solid #ddd; /* Add a border between columns */
}

::v-deep  .team_members .el-table th:last-child,
::v-deep  .team_members .el-table td:last-child {
  border-right: none; /* Remove the border on the last column */
}

.lci_btn {
  background-color: #f2f4f7;
  color: #667085;
  border-radius: 8px;
  padding: 12px 20px;
  border: 1px solid #eaecf0;
  width: 240px;
  &.save {
    background-color: #7f56d9;
    color: #fff;
  }
}


// .lci_btn {
//   background-color: #f2f4f7;
//   color: #667085;
//   border-radius: 8px;
//   padding: 10px 20px;
//   border: 1px solid #eaecf0;
//   width: 240px;
//   &.save {
//     background-color: #F2F4F7;
//     color: #667085;
//     width: 280px;
//     height: 44px;
//   }
// }
</style>