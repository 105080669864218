<template>
  <div class="w-100 m-0 p-0">
    <div class=" mt-2 ">
      <div class="main_table">
        <div class="border p-3" style="border-bottom: unset !important;  border-top-left-radius: 10px; border-top-right-radius: 10px;">
          <div class="text-black">What each member need from a team</div>
          <small class="text-muted">Select the learning pattern you want and see what each member bring to a team in this learning pattern</small>
        </div>
        <div class="border p-3" style="border-bottom: unset !important;">
          <span>See what each team member brings to the team in each learning pattern</span>
          <div class="pt-2">
            <el-radio-group v-model="radio">
              <el-radio :label="'sequence'">
                Sequence (S)
              </el-radio>
              <el-radio :label="'precision'">
                Precision (P)
              </el-radio>
              <el-radio :label="'technical_reasoning'">
                Technical Reasoning (T)
              </el-radio>
              <el-radio :label="'confluence'">
                Confluence (C)
              </el-radio>
              <!-- <el-radio :label="'all'">
                All
              </el-radio> -->
            </el-radio-group>
          </div>
        </div>
        <div class="header_container just_custom_table_tasks">
          <CustomTable
            :count="team_members.length"
            :DataItems="team_members"
            @Refresh="refresh"
            @reset="filter = {}"
            :filter="filter"
            :title="'Tasks'"
            hidden_export_import
            admin_permission="admin_tasks"
            edit_permission="add_tasks"
            class="team_members"
            :isGroup="true"
            :hasFilter="false"
            :selection="false"
          >
            <template #columns>
              <!-- <el-table-column type="selection" width="40"></el-table-column> -->
              <el-table-column prop="first_name" width="200" :label="`${localization('Member Name')}`" :style="{ borderRight: '1px solid #ddd' }" >
                <template slot-scope="props">
                  <div class="d-flex ">
                    <h5 class="m-0">{{ props.row.first_name }} </h5>
                    <h5 class="m-0">{{ props.row.last_name }}</h5>
                  </div>
                  <div>
                    <span>{{ props.row.classification }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="sequence" :label="`${localization('sequence')} (S)`" v-if="radio === 'sequence' || radio === 'all'" >
                <template slot-scope="props">
                  <span v-html="handleStatements(props.row.user_statements, 'Sequence')" > </span>
                </template>
              </el-table-column>
              <el-table-column prop="precision" :label="`${localization('Precision')} (P)`" v-if="radio === 'precision' || radio === 'all'">
                <template slot-scope="props">
                  <span v-html="handleStatements(props.row.user_statements, 'Precision')" > </span>
                </template>
              </el-table-column>
              <el-table-column prop="technical_reasoning " :label="`${localization('Technical Reasoning ')} (T)`" v-if="radio === 'technical_reasoning' || radio === 'all'" >
                <template slot-scope="props">
                  <span v-html="handleStatements(props.row.user_statements, 'technical_reasoning')" > </span>
                </template>
              </el-table-column>
              <el-table-column prop="confluence" :label="`${localization('Confluence')} (C)`" v-if="radio === 'confluence' || radio === 'all'">
                <template slot-scope="props">
                  <span v-html="handleStatements(props.row.user_statements, 'Confluence')" > </span>
                </template>
              </el-table-column>
            </template>
          </CustomTable>
        </div>
      </div>
      <div class="mt-3 d-flex justify-content-center mb-5">
        <!-- <button
          @click="prevTab"
          class="prev_next"
        >
          <img
            src="@/assets/new-theme/icons/arrow-narrow-left-active-Button.png"
            alt="edit icon"
          />
        </button> -->
        <button
          @click="$router.push({name: 'TeamsView'})"
          class="lci_btn save mx-4"
        >
          Back to teams
        </button>

        <!-- <button
          @click="nextTab"
          class="prev_next"
        >
          <img
            src="@/assets/new-theme/icons/arrow-narrow-right-Disable-Button.png"
            alt="edit icon"
          />
        </button> -->
      </div>
    </div>
  </div>
</template>
  
<script>
import { Card } from "src/components/index";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import { ChartCard, StatsCard } from "src/components/index";
export default {
  data() {
    return {
    
    };
  },
  components: {
    Card,
    ChartCard,
    StatsCard
  },
  computed: {
    ...mapGetters({
      loadinglabel: ["user/usersLoad"],
      team_details: ["user/get_team_details"],
      team_members: ["user/get_team_members"],
      invited_team_members: ["user/get_invited_team_members"]
    }),
    teamId() {
      return this.$route.params.id ? this.$route.params.id : null;
    },
  },
  data(){
    return {
      selected: [],
      filter:{},
      radio: 'sequence'
    }
  },
  mounted() {
    this.get_team_details();  
  },
  methods: {
    refresh(){
      this.$store.dispatch("user/every_team_members", this.teamId);
    },
    get_team_details() {
      this.$store.dispatch("user/every_team_members", this.teamId);
    },
    handleStatements(user_statements, pattern){
      if(user_statements){

        const user_statements_array = Object.values(user_statements)[1] 

        if(user_statements_array){
          const sequance = user_statements_array['Sequence']
          const confluence = user_statements_array['Confluence']
          const precision = user_statements_array['Precision']
          const technical_reasoning = user_statements_array['Technical Reasoning']
          if(pattern == 'Sequence'){
            return this.generateOlFromStatements(sequance)
          }else if(pattern == 'Confluence'){
            return this.generateOlFromStatements(confluence)
          }else if(pattern == 'Precision'){
            return this.generateOlFromStatements(precision)
          }else if(pattern == 'Technical Reasoning'){
            return this.generateOlFromStatements(technical_reasoning)
          }
        }
        
      }
    },

    generateOlFromStatements(patternArray) {
      // Flatten the object to get all statements
      const statements = Object.values(patternArray)
        .flat() // Combine all arrays into one
        .map(item => item.statement); // Extract only the statement property

      // Generate the <ol> tag with <li> for each statement
      const olTag = `<ol>${statements.map(statement => `<li>${statement}</li>`).join('')}</ol>`;
      
      return olTag;
    }
  }
};
</script>
<style scoped lang="scss">
::v-deep .el-tabs--border-card > .el-tabs__header {
  border-radius: 10px !important;
}
::v-deep .el-radio__input.is-checked+.el-radio__label{
  color:#000
}
.alert-custom {
  background-color: #fffaf0; /* Light background similar to your image */
  border: 1px solid #ffd580; /* Border color to match */
  color: #c35a00; /* Text color to match the orange */
  border-radius: 10px;
}

.alert-custom i {
  color: #c35a00; /* Icon color */
  font-size: 20px;
}

.alert-custom a {
  color: #c35a00;
  text-decoration: none;
}

.alert-custom a:hover {
  text-decoration: underline;
}

::v-deep .el-tabs--border-card, .el-tabs__content{
  border: unset !important;
}

::v-deep .el-tabs__nav-scroll{
  padding: 10px !important;
}

::v-deep .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active{
  border-top-color: #DCDFE6 !important;
  border-bottom-color: #DCDFE6 !important;
  border-radius: 7px !important;
}

::v-deep .team_members{
  .el-table .cell{
    overflow: unset !important;
  }
}

::v-deep.el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active{
  color: #344054;
}

::v-deep .el-tabs--border-card{
  box-shadow: unset !important;
}

::v-deep .el-tabs--border-card>.el-tabs__header{
  border-bottom: unset !important;
}

::v-deep  .new_theme_custom_table_pagination .card .card-body {
    padding: 0px 0px !important;
    border: unset !important;
}

::v-deep  .team_members .el-table th,
::v-deep  .team_members .el-table td {
  border-right: 1px solid #ddd; /* Add a border between columns */
}

::v-deep  .team_members .el-table th:last-child,
::v-deep  .team_members .el-table td:last-child {
  border-right: none; /* Remove the border on the last column */
}

.lci_btn {
  background-color: #f2f4f7;
  color: #667085;
  border-radius: 8px;
  padding: 12px 20px;
  border: 1px solid #eaecf0;
  width: 240px;
  &.save {
    background-color: #7f56d9;
    color: #fff;
  }
}
</style>