<template>
  <div>
    <div
      class="head_table padding d-flex align-items-center justify-content-between border rounded-top border-bottom-0"
      style=" border-top-left-radius: 10px !important; border-top-right-radius: 10px !important; "
    >
      <div class="p-2">
        <h2 class="main_title m-0 mb-2">
          {{ title }} <span class="total">{{ total }} {{ subTitle }} </span>
        </h2>
        <!-- <h2 class="main_title m-0 mb-1">
          {{ title }} <span class="total font-weight-normal">{{badge}}</span>
        </h2> -->
        <p class="mb-0 text">
          {{ text }}
        </p>
      </div>
      <div class="d-flex justify-content-center">
        <AppButton
          @click="ToggleJoinModal"
          :text="joinbuttonText"
          v-if="joinbuttonText"
          class="mr-2 btn btn-light"
        ></AppButton>
        <AppButton
          @click="ToggleInviteModal"
          :joinbtn="true"
          :text="invitebuttonText"
          v-if="invitebuttonText"
          class="mr-2 btn btn-light"
        ></AppButton>
        <AppButton
          @click="ToggleTaskModal"
          :text="buttonText"
          v-if="buttonText"
        ></AppButton>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TableHeaderSection",
  props: {
    // toggleModal: Function,
    title: {
      type: String,
      default: ""
    },
    subTitle: {
      type: String,
      default: ""
    },
    badge: {
      type: String,
      default: ""
    },
    text: {
      type: String,
      default: ""
    },
    buttonText: {
      type: String,
      default: ""
    },
    joinbuttonText: {
      type: String,
      default: ""
    },
    invitebuttonText: {
      type: String,
      default: ""
    },
    total: {
      type: [String, Number],
      default: "",
      required: false
    }
  },
  methods: {
    ToggleTaskModal() {
      this.$emit("toggleModal");
    },
    ToggleJoinModal() {
      this.$emit("toggleJoinModal");
    },
    ToggleInviteModal() {
      this.$emit("toggleInviteModal");
    },
  }
};
</script>

<style lang="scss" scoped>
.head_table {
  border-bottom: 1px solid #eaecf0;
  .total {
    background: #f2f4f7;
    border-radius: 16px;
    padding: 4px 10px;
    font-size: 14px;
    color: #344054;
  }
}

.main_title {
  color: #101828;
  font-weight: 500;
  font-size: 20px;
}
.padding {
  padding: 14px;
}
.text {
  color: #667085;
  font-weight: 400;
  font-size: 16px;
}
</style>
