<template>
  <el-dialog
    :visible.sync="showHideModalShare"
    top="4vh"
    width="35%"
    :show-close="false"
    class="user_modal statement_modal custom_share_modal"
  >
    <div class="user_modal_type w-100">
      <img class="img" src="@/assets/new-theme/profile/share-07.png" alt="" />

      <div>
        <h4 class="modal_title mb-0">
          {{ title }}
        </h4>
      </div>
      <div class="description_type">
        <p class="text_type mb-0">
          {{ description }}
        </p>
      </div>

      <div>
        <!-- <h4 class="modal_title mb-1">
          Share link
        </h4>
        <div class="copy_wrapper">
          <input
            class="input_link"
            disabled
            type="text"
            value="www.plc.com/test"
          />
          <span role="button" class="span_wrap" @click="copyLink()">
            <img class="img" src="@/assets/new-theme/icons/copy.png" alt="" />
            Copy
          </span>
        </div> -->
        <div class="form_share">
          <h4 class="modal_title mb-1">
            Email address
          </h4>
          <el-form
            :model="formData"
            :rules="share_profile_rules"
            ref="shareForm"
          >
            <div
              v-for="(email, index) in formData.emails"
              :key="index"
              class="each_emil_wrapper"
            >
              <el-form-item
                :rules="[
                  {
                    required: true,
                    message: 'Please input email address',
                    trigger: 'blur'
                  },
                  {
                    type: 'email',
                    message: 'Please input correct email address',
                    trigger: ['blur', 'change']
                  }
                ]"
                :prop="'emails.' + index"
              >
                <div class="email_wrapper">
                  <img
                    class="img"
                    src="@/assets/new-theme/icons/mail.png"
                    alt="mail"
                  />
                  <el-input
                    autocomplete="off"
                    v-model="formData.emails[index]"
                    placeholder="email@plc.com"
                  ></el-input>
                </div>
                <img
                  @click="removeEmail(index)"
                  v-if="formData.emails.length > 1"
                  class="delete_icon"
                  src="@/assets/new-theme/delete-button-table.png"
                  alt="delete"
                />
              </el-form-item>
              <!-- <input type="email" placeholder="email@plc.com" /> -->
            </div>
          </el-form>
          <div @click="addEmail()" class="your_own_statement ">
            <img
              role="button"
              src="@/assets/new-theme/profile/plus.png"
              alt="plus"
            />
            <span role="button">Add another</span>
          </div>
        </div>
      </div>

      <div class="lci_modal_buttons mt-4">
        <button @click="close()" class="lci_btn_modal_cancel ">
          Cancel
        </button>
        <button @click="submitShare()" class="lci_btn save">
          Share
        </button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "ShareModal",
  props: {
    showHideModalShare: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    },
    description: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      showModal: false,
      formData: {
        emails: [""] // Start with one empty email field
      },
      share_profile_rules: {
        email: [
          {
            required: true,
            type: "email",
            message: [
              "Please input email address",
              "Please input correct email address"
            ],
            trigger: ["blur", "change"]
          }
        ]
      }
    };
  },
  methods: {
    toggleModal() {
      this.showModal = !this.showModal;
    },
    addEmail() {
      this.formData.emails.push("");
    },
    removeEmail(index) {
      this.formData.emails.splice(index, 1); // Remove email at the given index
    },
    close() {
      this.$emit("close");
      this.formData.emails = [""];
    },
    async submitShare() {
      // Validate form before submitting
      this.$refs.shareForm.validate(valid => {
        if (valid) {
          console.log(
            "🚀 ~ this.$refs.shareForm.validate ~ this.formData.emails:",
            this.formData.emails
          );
          this.$emit("submit", this.formData.emails);
          this.close(); // Close modal after submitting
          this.formData.emails = [""]; // Clear the form after submission
        } else {
          console.error("Validation failed");
        }
      });
    },
    copyLink() {
      const url = "https://example.com"; // Replace with your URL
      navigator.clipboard
        .writeText(url)
        .then(() => {
          console.log("URL copied to clipboard");
        })
        .catch(err => {
          console.error("Failed to copy URL: ", err);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.custom_share_modal {
  &.user_modal {
    padding: 0;
    .el-dialog__header {
      display: none !important;
    }
    .user_modal_type {
      .modal_title {
        color: #101828;
        margin-top: 20px;
        margin-bottom: 10px;
        font-weight: 500;
        font-size: 18px;
      }
      .description_type {
        .text_type {
          color: #667085;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          word-break: break-word;
        }
      }

      .copy_wrapper {
        border: 1px solid #d0d5dd;
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;
        border-radius: 8px;
        .input_link {
          border: none;
          outline: none;
          padding: 0 10px;
          height: 44px;
          flex-grow: 1;
        }
        .span_wrap {
          padding: 0 10px;
          height: 44px;
          border-left: 1px solid #d0d5dd;
          width: 107px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
        }
      }

      .form_share {
      }
    }
  }
  &.statement_modal {
    .desc2 {
      color: #344054;
      font-weight: 500;
      font-size: 16px;
    }
    .text_area {
      border: 1px solid #d0d5dd;
      height: 92px;
      width: 100%;
      resize: none;
      border-radius: 8px;
      color: #101828;
      padding: 15px;
      outline: none;
      word-break: break-word;
    }
  }

  .lci_modal_buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3%;
    .lci_btn,
    .lci_btn_modal_cancel {
      background-color: #fff;
      color: #667085;
      border-radius: 8px;
      padding: 12px 20px;
      border: 1px solid #eaecf0;
      width: calc(97% / 2);
    }
    .lci_btn {
      background-color: #f2f4f7;
      &.save {
        background-color: #6941c6;
        color: #fff;
      }
    }
  }
  .your_own_statement {
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 500;
    color: #6941c6;
  }
}
</style>

<style lang="scss">
.custom_share_modal {
  &.user_modal {
    padding: 0;
    .el-dialog__header {
      display: none !important;
    }
  }

  .each_emil_wrapper {
    margin-bottom: 10px;
    .el-form-item__content {
      display: flex !important;
      align-items: center;
      // gap: 10px;
      .email_wrapper {
        display: flex;
        align-items: center;
        gap: 10px;
        flex-grow: 1;
        border: 1px solid #d0d5dd;
        border-radius: 8px;
        padding: 5px 10px;
        height: 42px;
        // .input_email {
        //   border: none;
        //   outline: none;
        //   flex-grow: 1;
        // }
        .el-input__inner {
          border: none;
          outline: none;
          padding: 0;
          // flex-grow: 1;
        }
      }
      .delete_icon {
        width: 40px;
        height: 40px;
        cursor: pointer;
      }
    }
  }
}
</style>
