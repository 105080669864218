import { render, staticRenderFns } from "./TableHeaderSection.vue?vue&type=template&id=45f3d2dc&scoped=true&"
import script from "./TableHeaderSection.vue?vue&type=script&lang=js&"
export * from "./TableHeaderSection.vue?vue&type=script&lang=js&"
import style0 from "./TableHeaderSection.vue?vue&type=style&index=0&id=45f3d2dc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45f3d2dc",
  null
  
)

export default component.exports